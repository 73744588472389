import React from "react";
import B2CLayout from "../../layouts/B2CLayout";

const B2CPublicRoutes = ({ children,subHeader,b2cheaderConfig }) => {

  return (
    <>
      <B2CLayout b2cheaderConfig={b2cheaderConfig} subHeader={subHeader} >
      {children}
      </B2CLayout>
    </>
  )
};

export default B2CPublicRoutes;
