import { 
  GET_COMPANY_INFO_REQUEST, 
  GET_COMPANY_INFO_SUCCESS, 
  GET_COMPANY_INFO_FAILURE,
  EDIT_COMPANY_INFO_REQUEST, 
  EDIT_COMPANY_INFO_SUCCESS, 
  EDIT_COMPANY_INFO_FAILURE, 
  FETCH_BRANDS_BY_COMPANY_REQUEST,
  FETCH_BRANDS_BY_COMPANY_SUCCESS,
  FETCH_BRANDS_BY_COMPANY_FAILURE
} from '../actions/company';

const initialCompanyState = {
  loading: false,
  companyInfo: null,
  error: null,
  editCompanyloading: false,
  editCompanyInfo: null,
  editCompanyerror: null,
  brands:[]
};

const companyReducer = (state = initialCompanyState, action) => {
  switch (action.type) {
    case GET_COMPANY_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        companyInfo: action.payload,
      };
    case GET_COMPANY_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EDIT_COMPANY_INFO_REQUEST:
      return {
        ...state,
        editCompanyloading: false,
        editCompanyerror: null,
      };
    case EDIT_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        editCompanyloading: true,
        editCompanyInfo: action.payload, // Update companyInfo with the updated data
      };
    case EDIT_COMPANY_INFO_FAILURE:
      return {
        ...state,
        editCompanyloading: false,
        editCompanyerror: action.payload,
      };
      case FETCH_BRANDS_BY_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BRANDS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: action.payload,
      };
    case FETCH_BRANDS_BY_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default companyReducer;
