import { FETCH_DISTILLERIES_BY_COMPANY_FAILURE, FETCH_DISTILLERIES_BY_COMPANY_REQUEST, FETCH_DISTILLERIES_BY_COMPANY_SUCCESS, FETCH_OTHER_DISLITTERY_FAILURE, FETCH_OTHER_DISLITTERY_REQUEST, FETCH_OTHER_DISLITTERY_SUCCESS } from "../actions/Distillery";

  
  const initialCompanyState = {
    loading: false,
    error: null,
    distilleries: [],
    otherDistilleries:[]
  };
  
  const distilleryReducer = (state = initialCompanyState, action) => {
    switch (action.type) {
      case FETCH_DISTILLERIES_BY_COMPANY_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_DISTILLERIES_BY_COMPANY_SUCCESS:
        return {
          ...state,
          loading: false,
          distilleries: action.payload, 
        };
       
      case FETCH_DISTILLERIES_BY_COMPANY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case FETCH_OTHER_DISLITTERY_SUCCESS:
          return {
            ...state,
            loading: false,
            otherDistilleries: action.payload,
          };
          case FETCH_OTHER_DISLITTERY_REQUEST:

            return {
              ...state,
              loading: true,
              error: null,
            };
            case FETCH_OTHER_DISLITTERY_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.payload,
              };
      default:
        return state;
    }
  };
  
  export default distilleryReducer;
  