import { combineReducers } from 'redux';
import todosReducer from './todoReducer';
import authReducer from './authReducer';
import companyReducer from './companyReducer';
import distilleryReducer from './distilleryReducer';
import releasesReducer from './releaseReducer';
import b2cReducer from './b2cReducer';
import brandReducer from './brandReducer';


const rootReducer = combineReducers({
  todos: todosReducer,
  auth:authReducer,
  company:companyReducer,
  distillery:distilleryReducer,
  releases: releasesReducer,
  b2c: b2cReducer,
  brand:brandReducer
});

export default rootReducer;
