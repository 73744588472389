import React from 'react';
import './ReviewBtn.css';
import rev_btn from '../../../../assets/img/rev_btn.png'
import save from '../../../../assets/img/save.png'
const ReviewBtn = () => {
    return (
        // <div className=' mt-md-5 mt-3 d-flex flex-column align-items-center justify-content-center'>
        <div className=' d-flex flex-column align-items-center justify-content-center'>

            <div className='rev_this_btn d-block w-100 pe-0'>
                <a href="#" className="text-decoration-none text-white d-flex align-items-center">
                    <img src={rev_btn} alt="" className="" />
                    <span className="ms-3 rev_text">REVIEW THIS</span>
                </a>
            </div>
            <div className='add_list_btn d-block w-100 pe-0'>
                <a href="#" className="text-decoration-none text-white d-flex align-items-center">
                    <img src={save} alt="" className="" />
                    <span className="ms-3 rev_text">ADD TO MY LIST</span>
                </a>
            </div>
        </div>
    )
}
export default ReviewBtn;