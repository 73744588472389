import { get } from "../../../services/apiService";
import { getB2CBrandByIdUrl, getB2CBrandsUrl, getB2CCompanyUrl, getB2CDistilleriesUrl, getB2CReleasesUrl } from "../../../services/urlHelper";
import { fetchB2CBrandByIdFailure, fetchB2CBrandByIdRequest, fetchB2CBrandByIdSuccess, fetchB2CBrandFailure, fetchB2CBrandRequest, fetchB2CBrandSuccess, fetchB2CCompanyDetailsFailure, fetchB2CCompanyDetailsRequest, fetchB2CCompanyDetailsSuccess, fetchB2CDistilleryFailure, fetchB2CDistilleryRequest, fetchB2CDistillerySuccess, fetchB2CReleaseFailure, fetchB2CReleaseRequest, fetchB2CReleaseSuccess } from "./actions";

  
  // Async action creator for fetching B2C brands
  export const getB2CBrands = () => async (dispatch) => {
    dispatch(fetchB2CBrandRequest());
    try {
      const response = await get(getB2CBrandsUrl()); 
      dispatch(fetchB2CBrandSuccess(response.data));
    } catch (error) {
      dispatch(fetchB2CBrandFailure(error.message));
    }
  };
  
  // Async action creator for fetching B2C distilleries
  export const getB2CDistilleries = () => async (dispatch) => {
    dispatch(fetchB2CDistilleryRequest());
    try {
      const response = await get(getB2CDistilleriesUrl()); 
      dispatch(fetchB2CDistillerySuccess(response.data));
    } catch (error) {
      dispatch(fetchB2CDistilleryFailure(error.message));
    }
  };
  
  // Async action creator for fetching B2C releases
  export const getB2CReleases = () => async (dispatch) => {
    dispatch(fetchB2CReleaseRequest());
    try {
      const response = await get(getB2CReleasesUrl()); 
      dispatch(fetchB2CReleaseSuccess(response.data));
    } catch (error) {
      dispatch(fetchB2CReleaseFailure(error.message));
    }
  };

  
  export const getB2CBrandById = (brandId) => async (dispatch) => {
    dispatch(fetchB2CBrandByIdRequest());
    try {
      const response = await get(getB2CBrandByIdUrl(brandId));
      dispatch(fetchB2CBrandByIdSuccess(response.data));
    } catch (error) {
      dispatch(fetchB2CBrandByIdFailure(error.message));
    }
  };

  export const getB2CCompanyDetails = () => async (dispatch) => {
    dispatch(fetchB2CCompanyDetailsRequest());
    try {
      const response = await get(getB2CCompanyUrl());
      dispatch(fetchB2CCompanyDetailsSuccess(response.data));
    } catch (error) {
      dispatch(fetchB2CCompanyDetailsFailure(error.message));
    }
  };