import React, { useEffect, useRef } from "react";
import "./GlobalGuide.css";
import Corosel from "./slide/Corosel";
import Review from "./Review/Review";
import bg from "../../../assets/img/brand_name_bg.png";
import circle_arrow from "../../../assets/img/circle_arrow.svg";
import one from "../../../assets/img/one.png";
import two from "../../../assets/img/two.png";
import three from "../../../assets/img/three.png";
import four from "../../../assets/img/four.png";
import pngaaa from "../../../assets/img/pngaaa.png";
import img0 from "../../../assets/img/noImage.jpeg";
import X from "../../../assets/img/X.png";
import F from "../../../assets/img/facebook.png";
import I from "../../../assets/img/insta.png";
import back_arrow from "../../../assets/img/back_arrow.png";
import back_arrow_black from "../../../assets/img/back_arrow_black.png";
import { Link, useNavigate } from "react-router-dom";

const NewGlobalGuide = ({ data }) => {
  const topDivRef = useRef(null);

  const navigate = useNavigate();
  const handleNavigateRelease = () => {
    navigate("/b2c/release");
  };

  useEffect(() => {
    topDivRef.current.scrollIntoView({ behavior: "smooth" });
    if (data?.flavour) {
      console.log(data.flavour);
    }
  }, []);

  return (
    <>
      <div ref={topDivRef} className="Global_container">
        <img src={bg} alt="bg" className="Global_bg_image_set" />
        <div className="d-flex flex-wrap">
          <div className="box1 d-flex flex-column align-items-center position-relative">
            <div className="box1_nav d-flex flex-column justify-content-center ps-2">
              <div className="backAArrow ps-2 cursor-pointer">
                <a
                  // href="javqascript:void();"
                  className="nav-link text-white d-md-flex align-items-center d-none "
                  // onClick={() => window.history.back()}
                >
                  <img
                    src={back_arrow}
                    alt=""
                    className="me-2 text-md-white text-black"
                  />
                  <span onClick={() => handleNavigateRelease()} className="">
                    Back
                  </span>
                </a>
                <a
                  // href="javqascript:void();"
                  className="nav-link text-white d-md-none d-flex align-items-center"
                  onClick={() => window.history.back()}
                >
                  <img
                    src={back_arrow_black}
                    alt=""
                    className="me-2 text-md-white text-black"
                  />
                  <span
                    onClick={() => handleNavigateRelease()}
                    className="text-black"
                  >
                    Back
                  </span>
                </a>
              </div>

              <ul className="nav flex-column">
                <li className="nav-item">
                  <a
                    href="#intro_global_release"
                    className="nav-link text-white d-flex align-items-center"
                  >
                    <img src={one} alt="" className="me-2" />
                    <span>INTRODUCTION</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#gallery_global_release"
                    className="nav-link text-white d-flex align-items-center"
                  >
                    <img src={two} alt="" className="me-2" />
                    <span>GALLERY</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#details_global_release"
                    className="nav-link text-white d-flex align-items-center"
                  >
                    <img src={three} alt="" className="me-2" />
                    <span>PRODUCT DETAILS</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#flavour_global_release"
                    className="nav-link text-white d-flex align-items-center"
                  >
                    <img src={four} alt="" className="me-2" />
                    <span>FLAVOURS</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="img_Position position-absolute bottle_img" style={{    top: '25%',
    left: '50%',
    transform: 'translateX(50%)'}}>
             {/* <p > { data?.logo}</p> */}
              <img
                src={
                  data?.logo
                    ? `${process.env.REACT_APP_PHOTOS_URL}/${data?.logo}`
                    : img0
                }
                alt="logo"
              />
            </div>
          </div>
          <div
            className="box2 py-5 px-md-5 px-3 bg-light "
            id="intro_global_release"
          >
            <div className="row">
              <div className="box2_one col-12 col-md-4 ps-md-5 ms-md-5 d-md-block d-none">
                <div className="info-section_space">
                  <div className="info-section">
                    <p className="info_section_strong mb-2">
                      <strong>PRICE BAND</strong>
                    </p>
                    <p className="info_section_p mb-1">
                      {data?.price ? data?.price : "Not Available"}
                    </p>
                  </div>
                  <div className="info-section">
                    <p className="info_section_strong mb-2">
                      <strong>ABV</strong>
                    </p>
                    <p className="info_section_p mb-1">
                      {data?.abv ? data?.abv : "Not Available"}
                    </p>
                  </div>
                  <div className="info-section">
                    <p className="info_section_strong mb-2">
                      <strong>PRODUCTION TYPE</strong>
                    </p>
                    <p className="info_section_p mb-1"> {data?.type ? data?.type : "Not Available"}</p>
                  </div>
                  <div className="info-section">
                    <p className="info_section_strong mb-2">
                      <strong>REGION</strong>
                    </p>
                    <p className="info_section_p mb-1">
                      {data?.region ? data?.region : "Not Available"}
                    </p>
                  </div>
                  <div className="info-section">
                    <p className="info_section_strong mb-2">
                      <strong>Flavours</strong>
                    </p>
                    {data?.flavour?.length > 0 &&
                      data.flavour.map((item, index) => (
                        <p key={index} className="info_section_p mb-1">
                          {item}
                        </p>
                      ))}
                  </div>
                  <div className="info-section">
                    <p className="info_section_strong mb-2">
                      <strong>MEDIA</strong>
                    </p>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        width="25"
                        height="20"
                        viewBox="0 0 23 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.6372 0H20.9452L13.7182 8.26L22.2202 19.5H15.5622L10.3482 12.683L4.38222 19.5H1.07222L8.80222 10.665L0.647217 0H7.47222L12.1852 6.231L17.6352 0H17.6372ZM16.4762 17.52H18.3092L6.47722 1.876H4.51022L16.4762 17.52Z"
                          fill="#000000"
                        />
                      </svg>

                      <p className="info_section_p mt-2">@release1</p>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 12 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.64722 11.5H10.1472L11.1472 7.5H7.64722V5.5C7.64722 4.47 7.64722 3.5 9.64722 3.5H11.1472V0.14C10.8212 0.0970001 9.59022 0 8.29022 0C5.57522 0 3.64722 1.657 3.64722 4.7V7.5H0.647217V11.5H3.64722V20H7.64722V11.5Z"
                          fill="#000000"
                        />
                      </svg>

                      <p className="info_section_p mt-3 mb-3">@release1</p>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.93164 14C10.9925 14 12.0099 13.5786 12.7601 12.8284C13.5102 12.0783 13.9316 11.0609 13.9316 10C13.9316 8.93913 13.5102 7.92172 12.7601 7.17157C12.0099 6.42143 10.9925 6 9.93164 6C8.87077 6 7.85336 6.42143 7.10321 7.17157C6.35307 7.92172 5.93164 8.93913 5.93164 10C5.93164 11.0609 6.35307 12.0783 7.10321 12.8284C7.85336 13.5786 8.87077 14 9.93164 14Z"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M0.931641 14V6C0.931641 4.67392 1.45842 3.40215 2.39611 2.46447C3.33379 1.52678 4.60556 1 5.93164 1H13.9316C15.2577 1 16.5295 1.52678 17.4672 2.46447C18.4049 3.40215 18.9316 4.67392 18.9316 6V14C18.9316 15.3261 18.4049 16.5979 17.4672 17.5355C16.5295 18.4732 15.2577 19 13.9316 19H5.93164C4.60556 19 3.33379 18.4732 2.39611 17.5355C1.45842 16.5979 0.931641 15.3261 0.931641 14Z"
                          stroke="#000000"
                          stroke-width="1.5"
                        />
                        <path
                          d="M15.4316 4.51002L15.4416 4.49902"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <p className="info_section_p mt-3 mb-3">@release1</p>
                    </div>
                  </div>
                </div>
              </div>
              {console.log("data", data)}
              <div className="box2_two col-12 col-md-6 d-flex justify-center flex-column">
                {/* <h3>{data?.type ? data?.type : "Not Available"}</h3> */}
                <h3>{data?.name ? data?.name : "Not Available"}</h3>
                <p className="box2_two_p">
                  {data?.type ? data?.type : "Not Available"} |{" "}
                  <span className="favorite info_section_strong">
                    <i className="fa fa-bookmark"></i> Favorite
                  </span>
                </p>
                <p className="box2_two_content">
                  {data?.location ? data?.location : "Not Available"}
                </p>
                <p className="box2_two_para">
                  {data?.description ? data?.description : "Not Available"}
                </p>

                <button className="btn release_page_box2_btn">
                  {" "}
                  <img className="" src={circle_arrow} alt="" />
                  Find stores nearby
                </button>

                <div className="d-md-none d-block">
                  <div className="info-section_mobile p-3 mt-3">
                    <div className="row">
                      <div className="col-6 info-section">
                        <p className="info_section_strong mb-2">
                          <strong>PRICE BAND</strong>
                        </p>
                        <p className="info_section_p mb-1">
                          {data?.price ? data?.price : "Not Available"}
                        </p>
                      </div>

                      <div className="col-6 info-section">
                        <p className="info_section_strong mb-2">
                          <strong>ABV</strong>
                        </p>
                        <p className="info_section_p mb-1">
                          {data?.ean ? data?.ean : "Not Available"}
                        </p>
                      </div>

                      <div className="col-6 info-section">
                        <p className="info_section_strong mb-2">
                          <strong>PRODUCTION TYPE</strong>
                        </p>
                        <p className="info_section_p mb-1">
                          {data?.type ? data?.type : "Not Available"}
                        </p>
                      </div>

                      <div className="col-6 info-section">
                        <p className="info_section_strong mb-2">
                          <strong>REGION</strong>
                        </p>
                        <p className="info_section_p mb-1">
                          {data?.region ? data?.region : "Not Available"}
                        </p>
                      </div>
                      <div className="col-6 info-section">
                        <p className="info_section_strong mb-2">
                          <strong>FLAVOUR CAMP</strong>
                        </p>
                        <p className="info_section_p mb-1">
                          {data?.flavour
                            ? data?.flavour.map((item) => item)
                            : "Not Available"}
                        </p>
                      </div>
                      <div className="col-6 info-section">
                        <p className="info_section_strong mb-2">
                          <strong>MEDIA</strong>
                        </p>
                        <div className="d-flex align-items-center">
                          <img className="box2_one_images" src={X} alt="" />
                          <p className="info_section_p mb-3">@release1</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <img className="box2_one_images" src={F} alt="" />
                          <p className="info_section_p mb-3">@release1</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <img className="box2_one_images" src={I} alt="" />
                          <p className="info_section_p mb-3">@release1</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="background_image_GG">
          <div className="cosel_box mt-5" id="gallery_global_release">
            <div>
              <p className="gallery_heading">Gallery</p>
            </div>
            <Corosel data={data} />
          </div>

          <div className="">
            <Review data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewGlobalGuide;
