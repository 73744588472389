import img1 from "../../../assets/img/noImage.jpeg";
import img3 from "../../../assets/img/subtract.png";
import img4 from "../../../assets/img/arrow41.svg";
import { useEffect, useState } from "react";
import { getB2CBrands } from "../../../store/actions/b2c";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import "./BrandComp.css";
import '../../../components/ListPages/ReleaseCard/ReleaseCard.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function BrandComp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { brands } = useSelector((state) => state.b2c);
  const [expandedTextId, setExpandedTextId] = useState(null);
  const [selectedButton, setSelectedButton] = useState("All"); // State variable to store the selected button

  const handleTextExpand = (id) => {
    setExpandedTextId(id);
  };


 

  const isTextExpanded = (id) => {
    return expandedTextId === id;
  };

  useEffect(() => {
    dispatch(getB2CBrands());
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const renderDescription = (description, id) => {
    const maxLength = 200;
    if (description.length > maxLength) {
      return (
        <>
          {isTextExpanded(id) ? (
            <>
              {description}
              &nbsp;
              <span
                className="read-more text-[#BA9775]"
                onClick={() => handleTextExpand(null)}
              >
                Read less
              </span>
            </>
          ) : (
            <>
              {`${description.substring(0, maxLength)}...`}
              &nbsp;
              <span
                className="read-more text-[#BA9775]"
                onClick={() => handleTextExpand(id)}
              >
                Read more
              </span>
            </>
          )}
        </>
      );
    } else {
      return description;
    }
  };

  function renderCard(index, i) {
    return (
      <div className=" my-4">
        <div className="border_box">
          <div className="row  mb-3 highland_box">
            <div
              className="col-md-2 highland_img_box"
              style={{ maxWidth: "94px", maxHeight: "69px" }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={
                  i?.logo
                    ? `${process.env.REACT_APP_API_URL}/uploads/${i?.logo}`
                    : img1
                }
                alt="Highland Park logo"
                className=""
              />
            </div>
            <div className="col-md-9">
              <p className="description_para ms-md-2">
                {renderDescription(i?.story, index)}
              </p>
            </div>
            <div className="col-md-1">
              <span className="position_grid">
                <img src={img3} alt="" />
              </span>
            </div>
          </div>

          <div className="trending_box">
            <div className="row">
              <div className="col-md-6">
                {i?.releases?.length > 0 && (
                  <h3 className="highland_header mb-3 text-white">
                    TRENDING RELEASES
                  </h3>
                )}
              </div>
              <div className="col-md-6">
                <a
                  style={{ textDecoration: "none" }}
                  className="explore_link align-items-center d-flex bg-transparent align-items-center"
                >
                  <span className="explore_more6 text_peach">Explore more</span>
                  <span className="explore_link_inner  pt-0">
                    <img className="frame_child17" alt="" src={img4} />
                  </span>
                </a>
              </div>
            </div>
            {i?.releases?.length ? (
              <div
                id="imageCarousel"
                className="carousel slide"
                data-bs-ride="false"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row">
                      <Slider {...settings} className="pt-2">
                        {i?.releases.map((k, index) => (
                          <div
                            key={index}
                            className="col-md-4 hover:cursor-pointer p-2"
                          >
                            <div
                              onClick={() => navigate(`/b2c/detail/${k._id}`)}
                              className="scroll-item h-2/3"
                            >
                              <img
                                src={`${process.env.REACT_APP_API_URL}/uploads/${k?.coverImage}`}
                                className="object-cover  " style={{height:'223px', width:'183px'}}
                                alt="Trending product"
                              />
                              <p className="sub_heading mb-0 lh-sm">
                                {k?.name}
                              </p>
                            </div>
                            
                          </div>
                          
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container pt-4  pt-xl-4">
        <div className="release-const">
          <div className="release-h-inp d-flex justify-content-between align-items-center mb-4">
            <div className="release-head d-flex justify-content-between align-items-center ps-3">
              <h2>
                <a className="rel-link text-decoration-none text-light">
                  Brands
                </a>
              </h2>
              <i className="fas fa-search text-light" />
            </div>
            <div className="r-inp-search position-relative d-none">
              <div className="re-input px-2">
                <input
                  type="text"
                  className="form-control bg-transparent"
                  placeholder="Search..."
                />
                <i className="fa fa-search" />
              </div>
            </div>
          </div>
          <div className="bottom-border3"></div>
          <div
            className="btn-group release-btns mb-4 ps-3 gap-3 flex-wrap"
            role="group"
            aria-label="Filter options"
          >
            <button type="button r1-btn"  className={`btn btn-outline-secondary ${selectedButton === "All" ? "active" : ""}`} >
              All
            </button>
            <button type="button" className="btn btn-outline-secondary">
              Top
            </button>
            <button type="button" className="btn btn-outline-secondary">
              Favorite
            </button>
            <button type="button" className="btn btn-outline-secondary">
              Recommended
            </button>
            <button type="button" className="btn btn-outline-secondary">
              New Release
            </button>
            <button type="button" className="btn btn-outline-secondary">
              Tasted
            </button>
          </div>

          <section className="brand_page">
            <div className="container px-md-5 px-3">
              <div className="row pt-3">
                {brands?.data?.data
                  ? brands?.data?.data.map((i, index) => (
                      <div key={index}>{renderCard(index, i)}</div>
                    ))
                  : ""}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
