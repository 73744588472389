import React from "react";
import "./HeaderFooter.css";
import img from "../assets/images/logo.png";
import img2 from "../assets/images/Grouptwo.svg";
import img3 from "../assets/images/Vector.svg";
import img4 from "../assets/images/Frame.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoSvg from "../assets/svg/LogoSvg";

const B2CHeader = () => {
  const location = useLocation()

  
  return (
    <header>
      <div className="ct_toggle_bar ibar">
        <i className="fa-solid fa-bars"></i>
      </div>
      <div className="ct_toggle_bar text-center ps-sm-5 ms-sm-5 text-white">
      <Link to="/">
                <LogoSvg/>
                </Link>
      </div>
      <div className="ct_right_menu">
        <ul className="mb-0 ps-0">
          <li className="sign">
            <i className="fa-solid fa-magnifying-glass"></i>
          </li>
          <>
            <li className="sign">
              <i className="fa-regular fa-bookmark"></i>
            </li>
            {!location.pathname.includes('signin') ? <li className="sign"><Link to="/signin">Sign In</Link></li> : <li className="sign"><Link to="/">Home</Link></li>}
            {/* <li className="sign"><a href="#">Contact</a></li> */}
            <li className="signs">
              <a href="#">
                <img src="" alt="" />
              </a>
            </li>
            <li className="signs">
              <a href="#">
                <img src={img2} alt="" />
              </a>
            </li>
            <li className="signs">
              <a href="#">
                <img src={img3} alt="" />
              </a>
            </li>
            <li className="signs">
              <a href="#">
                <img src={img4} alt="" />
              </a>
            </li>
          </>
        </ul>
      </div>
    </header>
  );
};

export default B2CHeader;
