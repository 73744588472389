import React, { useState } from 'react';
import './Notes.css';
import Arrow_back from "../../../../assets/img/Arrow_back.png";
import Arrow_next from "../../../../assets/img/Arrow_next.png";
import dbl_quote from "../../../../assets/img/dbl_quote.png";
import drop from "../../../../assets/img/drop.png"

const Notes = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <>
            <div className='notes mt-md-3 mt-3 p-md-5 p-3 ' id="notes_global_release">
                <div className='d-flex justify-content-between align-items-center p-2'>
                    <h3 className='notes_heading'>TASTING NOTES</h3>
                    <div className=' d-md-flex gap-3  d-none'>
                        <img src={Arrow_back} alt="back_arrow" className='arrows' />
                        <img src={Arrow_next} alt="Arrow_next" className='arrows' />
                    </div>
                </div>
                <div className='row '>
                    <div className='col-md-8 col-12 first_part'>
                        <img src={dbl_quote} alt="dbl_quote" className='' />
                        {/* <p className='mb-0 notes_para'>
                            {isExpanded ? (
                                <>
                                    "The color of this cognac has a very pleasant golden tint and the aromatics show classic scents of baked, white grapes, hard lemon candy, and opulent French oak. True to the product’s marketing description and perhaps in discord with the line’s name, “Eleganse,” the palate is bold and rich with a pleasant impression of grippy oak tannin. Although perhaps slightly misnamed, this would be a wonderful cognac for whisky lovers and its concentration of wood and fruit flavors would make for great New Orleans style cocktails."
                                </>
                            ) : (
                                <>
                                    "The color of this cognac has a very pleasant golden tint and the aromatics show classic scents of baked, white grapes, hard lemon candy, and opulent French oak. True to the ..."
                                </>
                            )}
                        </p>
                        <button className='btn btn-link p-0 d-md-none text-white text-decoration-none' onClick={toggleExpand}>
                            {isExpanded ? 'Read less' : 'Read more'}
                        </button> */}



                        <p className={`mb-0 notes_para ${isExpanded ? 'expanded' : ''}`}>
                            "The color of this cognac has a very pleasant golden tint and the aromatics show classic scents of baked, white grapes, hard lemon candy, and opulent French oak. True to the product’s marketing description and perhaps in discord with the line’s name, “Eleganse,” the palate is bold and rich with a pleasant impression of grippy oak tannin. Although perhaps slightly misnamed, this would be a wonderful cognac for whisky lovers and its concentration of wood and fruit flavors would make for great New Orleans style cocktails."
                        </p>
                        <button className='btn btn-link p-0 d-md-none text-white text-decoration-none' onClick={toggleExpand}>
                            {isExpanded ? 'Read less' : 'Read more'}
                        </button>







                        <div className='text-end me-md-4'><img src={dbl_quote} alt="dbl_quote" className='' /></div>
                    </div>
                    <div className='snd_part col-md-4 col-12 d-flex flex-column justify-content-center '>
                        <p className='n_font-16'>Added by</p>
                        <p className='n_font-28'>JOHN RANKIN</p>
                        <p className='n_font-16'>Score: 90 <span><img src={drop} alt="drop" className='ms-1' /></span></p>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Notes;