import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainHeader from '../components/SubHeader/MainHeader';
import B2CHeader from '../components/B2CHeader';

const B2CLayout = ({ children, subHeader = [],b2cheaderConfig }) => {
    return (
        <>
            <B2CHeader />
            <MainHeader headerConfig={b2cheaderConfig} subHeader={subHeader} />
            {children}
            <Footer />
        </>
    )
};

export default B2CLayout;
