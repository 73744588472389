import React from 'react';
import './Flavours.css';
import Flavour_header from './Flavour_header';
import RoseTaste from "../../../../assets/img/RoseTaste.png"
const Flavours = ({data}) => {
    return (
        <div className='flavours mt-md-3 mt-2 p-md-5' id='flavour_global_release'>
            <div className='row'>
                <div className='col-md-7 col-12 f_first_part'>
                <Flavour_header />
                    <table className="table table-bordered flavour_table mt-md-3">
                        <tbody>
                            <tr>
                                <td>
                                    <p className="f_font_16">Color</p>
                                    <p className="f_font_14">{data?.color ? data?.color : 'Not Available'}</p>
                                </td>
                                <td>
                                    <p className="f_font_16">Nose</p>
                                    <p className="f_font_14">{data?.nose ? data?.nose : 'Not Available'}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="f_font_16">Palate</p>
                                    <p className="f_font_14">{data?.palate ? data?.palate : 'Not Available'}</p>
                                </td>
                                <td>
                                    <p className="f_font_16">Finish</p>
                                    <p className="f_font_14">{data?.finish ? data?.finish : 'Not Available'}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='col-md-5 col-12 flavour_snd_part'>
                    <div className='flvr_secPart_img d-flex align-items-center' >
                    <img src={RoseTaste} alt="back_arrow" className='RoseTaste' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Flavours;