import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const RadarChartMain = ({ labelsArray = [], valuesArray = [] }) => {
  const dummyLabel = [
    'Peaty/Smokey',
    'Pear/Apple',
    'Grass/Citrus',
    'Floral/Herbal',
    'Toffee/Vanilla',
    'Nutty/Jolly',
    'Dried Fruits',
    'Woody/Spicy'
  ]
  const dummyData = [3, 1, 2, 1, 4, 3, 2, 4]
  const data = {
    labels: labelsArray.length > 0 ? labelsArray : [],
    datasets: [{
      label: '',
      data:valuesArray.length>0 ? valuesArray : [] ,
      fill: true,
      backgroundColor: 'rgb(255 255 255 / 50%)',
      borderColor: 'rgb(186 151 117)', // Set border color to transparent
      pointBackgroundColor: 'rgb(255 255 255 / 0%)',
      pointBorderColor: 'rgb(255 255 255 / 0%)',
      //   pointHoverBackgroundColor: '#fff',
      //   pointHoverBorderColor: 'rgba(255, 159, 64, 1)'
    }]
  };

  const options = {
    elements: {
      line: {
        borderWidth: 0 // Remove the border in the filled area
      }
    },
    scales: {
      r: {
        angleLines: {
          color: 'rgb(186 151 117)',
        },
        grid: {
          circular: true, // Make the grid circular
          color: 'rgb(186 151 117)',
        },
        pointLabels: {
          color: 'rgb(186 151 117)',
          font: {
            size: 12,
            family: 'Inter',
          }
        },
        suggestedMin: 0,
        suggestedMax: 5,
        ticks: {
          display: false
        }
      }
    }

  };

  return (
    <div className='radar_chart_state'>
      <Radar data={data} options={options} />
    </div>
  );
};

export default RadarChartMain;
