import React, { useState } from 'react'
import "../App.css"
// import { Modal } from 'bootstrap';
import Modal from 'react-bootstrap/Modal';
const SuccessModel = (props) => {
    return (
        // <Modal centered show={props.show} onHide={props.handleClose} >
        // <Modal.Header closeButton>
        //   <Modal.Title>Modal heading</Modal.Title>
        // </Modal.Header>
        // <Modal.Body style={{padding:'0', maxWidth: "28.375rem"}}>

        <div
            className="card text-white bg-black py-4"
            style={{ maxWidth: "28.375rem", backgroundColor: "black" }}
        >
            <div className="card-body">
                <h2
                    style={{ fontFamily: "Cormorant-Infant", fontSize: 24 }}
                    className="card-title fw-bold mb-3 text-center"
                >
                    {props?.title}
                    {/* NEW BRAND HAS BEEN CREATED */}
                </h2>
                {props?.message ?
                    <p
                        style={{ fontFamily: "Inter", fontSize: 14 }}
                        className="card-text mb-3 text-center"
                    >
                        {props?.message}
                        {/* Do you wish to proceed with adding the releases of your company to present
                    them on {"{"}Global Guide{"}"} platform? */}
                    </p> : ''
                }
                {
                    props?.button ?
                        <div className="d-flex justify-content-center gap-4 ">
                            <button onClick={props?.button?.action} className="btn btn-outline-light me-2">{props?.button?.label}</button>
                            {/* <button className="btn" style={{ backgroundColor: "#c5a47e" }}>
        Add Release
    </button> */}
                        </div>
                        : ''
                }

            </div>
        </div>
        //   </Modal.Body>
        //   <Modal.Footer>
        //     <Button variant="secondary" onClick={handleClose}>
        //       Close
        //     </Button>
        //     <Button variant="primary" onClick={handleClose}>
        //       Save Changes
        //     </Button>
        //   </Modal.Footer> 
        // </Modal>


    )

}
export default SuccessModel;