import React from 'react'
import './HeaderFooter.css'
import img from '../assets/images/logo.png'
import img2 from '../assets/images/Grouptwo.svg'
import img3 from '../assets/images/Vector.svg'
import img4 from '../assets/images/Frame.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LogoSvg from '../assets/svg/LogoSvg'

const Header = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const token = localStorage.getItem('authToken')
    const handleSignOut = () => {
        localStorage.removeItem('authToken')
        navigate('/')
    }
    return (
        <header>
            <div className="ct_toggle_bar ibar">
                {/* <i className="fa-solid fa-bars"></i> */}
               <div className="Toggle_Navbar_Menu_Icon">
                <span></span>
               </div>
            </div>
            <div className="ct_toggle_bar text-center ps-sm-5 ms-sm-5 text-white">
                <Link to="/">
                <LogoSvg/>
                </Link>
            </div>
            <div className="ct_right_menu">
                <ul className="mb-0 ps-0">
                    <li className="sign">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </li>
                    {!token ? <>
                        <li className="sign">
                            <i className="fa-regular fa-bookmark"></i>
                        </li>
                        {!location.pathname.includes('signin') ? <li className="sign"><Link to="/signin">Sign In</Link></li> : <li className="sign"><Link to="/">Home</Link></li>}
                        <li className="sign"><a href="#">Contact</a></li>
                        <li className="signs"><a href="#"><img src='' alt="" /></a></li>
                        <li className="signs"><a href="#"><img src={img2} alt="" /></a></li>
                        <li className="signs"><a href="#"><img src={img3} alt="" /></a></li>
                        <li className="signs"><a href="#"><img src={img4} alt="" /></a></li>
                    </> : <li className="sign"><a href="#" onClick={() => handleSignOut()}>Sign Out</a></li>}
                </ul>
            </div>
        </header>
    )
}

export default Header