export const FETCH_DISTILLERY_REQUEST = 'FETCH_DISTILLERY_REQUEST';
export const FETCH_DISTILLERY_SUCCESS = 'FETCH_DISTILLERY_SUCCESS';
export const FETCH_DISTILLERY_FAILURE = 'FETCH_DISTILLERY_FAILURE';

export const FETCH_DISTILLERIES_BY_COMPANY_REQUEST = 'FETCH_DISTILLERIES_BY_COMPANY_REQUEST';
export const FETCH_DISTILLERIES_BY_COMPANY_SUCCESS = 'FETCH_DISTILLERIES_BY_COMPANY_SUCCESS';
export const FETCH_DISTILLERIES_BY_COMPANY_FAILURE = 'FETCH_DISTILLERIES_BY_COMPANY_FAILURE';


export const FETCH_OTHER_DISLITTERY_REQUEST = 'FETCH_OTHER_DISLITTERY_REQUEST';
export const FETCH_OTHER_DISLITTERY_SUCCESS = 'FETCH_OTHER_DISLITTERY_SUCCESS';
export const FETCH_OTHER_DISLITTERY_FAILURE = 'FETCH_OTHER_DISLITTERY_FAILURE';