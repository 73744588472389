import React from 'react';
import './SideCards.css';
import sidecard from '../../../../assets/img/sidecard.png'
import sideArrow from '../../../../assets/img/sideArrow.png'
import sidecard2 from '../../../../assets/img/sidecard2.png'

const SideCards = () => {
    return (
        <>
 <div className='d-md-block d-none'>
                <div className='sidecard_component'>
                    <img src={sidecard} alt='sidecard' className="w-100" />
                    <div className='sidecard_component_div p-3'>
                        <p>BONOLLO GRAPPA OF AMARO NEBARRIQUE</p>
                        <div className='text-end'><a className=" sidecard_component_btn text-decoration-none"><span>Explore more </span><img src={sideArrow} alt='sideArrow' className="ms-2" /></a></div>

                    </div>
                </div>


                <div className='mt-md-4'>
                    <div className='sidecard_component d-block w-100'>
                        <img src={sidecard} alt='sidecard' className="w-100" />
                        <div className='sidecard_component_div p-3'>
                            <p>BONOLLO GRAPPA OF AMARO NEBARRIQUE</p>
                            <div className='text-end'><a className=" sidecard_component_btn text-decoration-none"><span>Explore more </span><img src={sideArrow} alt='sideArrow' className="ms-2" /></a></div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='d-md-none d-block'>

                <div className=''>
                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">

                                <div className='sidecard_component d-block w-100'>
                                    <img src={sidecard} alt='sidecard' className="w-100" />
                                    <div className='sidecard_component_div p-3'>
                                        <p>BONOLLO GRAPPA OF AMARO NEBARRIQUE</p>
                                        <div className='text-end'><a className=" sidecard_component_btn text-decoration-none"><span>Explore more </span><img src={sideArrow} alt='sideArrow' className="" /></a></div>

                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">

                                <div className='sidecard2_component d-block w-100'>
                                    <img src={sidecard2} alt='sidecard2' className="w-100" />
                                    <div className='sidecard2_component_div p-3'>
                                        <p>PÈRE MAGLOIRE CALVADOS VSOP LESS FLORAL</p>
                                        <div className='text-end'><a className=" sidecard2_component_btn text-decoration-none"><span>Explore more </span><img src={sideArrow} alt='sideArrow' className="" /></a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className='sidecard_component'>
                                    <img src={sidecard} alt='sidecard' className="w-100" />
                                    <div className='sidecard_component_div p-3'>
                                        <p>BONOLLO GRAPPA OF AMARO NEBARRIQUE</p>
                                        <div className='text-end'><a className=" sidecard_component_btn text-decoration-none"><span>Explore more </span><img src={sideArrow} alt='sideArrow' className="" /></a></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )

}
export default SideCards;