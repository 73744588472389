import { fetchDistilleryRequest, fetchDistillerySuccess, fetchDistilleryFailure, fetchDistilleriesByCompanyRequest, fetchDistilleriesByCompanySuccess, fetchDistilleriesByCompanyFailure, fetchOtherDistilleryFailure, fetchOtherDistillerySuccess, fetchOtherDistilleryRequest } from './actions';

import { ADD_DISTILLERY, addDistilleryUrl, editDistilleryUrl, getDistilleriesByCompanyUrl, getOtherDistilleryUrl } from '../../../services/urlHelper';
import { get, post, put } from '../../../services/apiService';

export const addDistillery = (data) => async (dispatch) => {
  dispatch(fetchDistilleryRequest());
  try {
    const response = await post(addDistilleryUrl(),data);
    dispatch(fetchDistillerySuccess(response.data));
  } catch (error) {
    dispatch(fetchDistilleryFailure(error.message));
  }
};

export const editDistillery = (id,data) => async (dispatch) => {
  dispatch(fetchDistilleryRequest());
  try {
    const response = await put(`${ADD_DISTILLERY}/${id}`,data);
    dispatch(fetchDistillerySuccess(response.data));
  } catch (error) {
    dispatch(fetchDistilleryFailure(error.message));
  }
};
export const fetchDistilleriesByCompany = (companyId) => async (dispatch) => {
  dispatch(fetchDistilleriesByCompanyRequest());
  try {
    const response = await get(getDistilleriesByCompanyUrl(companyId));
    dispatch(fetchDistilleriesByCompanySuccess(response.data));
  } catch (error) {
    dispatch(fetchDistilleriesByCompanyFailure(error.message));
  }
};
export const fetchOthersDistilleries = () => async (dispatch) => {
  dispatch(fetchOtherDistilleryRequest());
  try {
    const response = await get(getOtherDistilleryUrl());
    dispatch(fetchOtherDistillerySuccess(response.data));
  } catch (error) {
    dispatch(fetchOtherDistilleryFailure(error.message));
  }
};