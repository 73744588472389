import React, { useState } from "react";
import "./Review.css";
import Notes from "../Notes/Notes";
import Flavours from "../Flavours/Flavours";
import ReviewBtn from "../ReviewButton/ReviewBtn";
import BrandIntro from "../Brand_Introduction/BrandIntro";
import SideCards from "../Side_cards/SideCards";
import star1 from "../../../../assets/img/star1.png";
import star5 from "../../../../assets/img/star5.png";
import Review_rec_img1 from "../../../../assets/img/Review_rec_img1.png";
import Review_rec_img2 from "../../../../assets/img/Review_rec_img2.png";
import Review_rec_img3 from "../../../../assets/img/Review_rec_img3.png";
import Review_rec_img4 from "../../../../assets/img/Review_rec_img4.png";
import Review_rec_img5 from "../../../../assets/img/Review_rec_img5.png";
const Review = ({ data }) => {
  const [activeTab, setActiveTab] = useState("DETAILS");
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<img key={i} src={star1} alt={`star ${i}`} className="" />);
      } else {
        stars.push(<img key={i} src={star5} alt={`star ${i}`} className="" />);
      }
    }
    return stars;
  };

  const renderContent = () => {
    switch (activeTab) {
      case "DETAILS":
        return (
          <div>
            <h4 className="r_font-20 ">COMMUNITY RATING:</h4>
            <div className="d-flex align-items-center gap-3 mb-2 justify-content-md-start justify-content-center">
              <p className="r_font-56 mb-0">
                {data?.communityRating
                  ? parseFloat(data.communityRating).toFixed(1)
                  : "Not Available"}
              </p>
              <div className="review_stars d-flex gap-1">
                {data?.communityRating
                  ? renderStars(data?.communityRating)
                  : renderStars(0)}
                {/* <img src={star1} alt="" className="" />
                <img src={star1} alt="" className="" />
                <img src={star1} alt="" className="" />
                <img src={star1} alt="" className="" />
                <img src={star5} alt="" className="" /> */}
              </div>
              <p className="r_font-24 mb-0">
                (
                {`${
                  data?.totalPersonRating
                    ? data?.totalPersonRating
                    : "Not Available"
                }`}
                )
              </p>
            </div>
            <p className="review_para">
              Bache-Gabrielsen is a revered cognac house started in 1905. The
              “Natur & Eleganse” line is supposed to be a break from their
              classical line signified with an non-traditional, short, squat
              bottle and a flavor profile that highlights “no-nonsense," “frank
              and fruity characters.” The four-plus year old brandy is all ugni
              blanc sourced from vines in mostly the Fins Bois, but also the
              Petite Champagne and Grande Champagne crus.
            </p>
            <div className="d-flex flex-wrap gap-md-5 gap-2">
              <div>
                <p className="r_font-16">ABV</p>
                <p className="r_font-28">
                  {data?.abv ? data?.abv : "Not Available"}
                </p>
              </div>
              <div>
                <p className="r_font-16 ms-md-0 ms-5">Cask type</p>
                <p className="r_font-28 ms-md-0 ms-5">
                  {data?.caskType ? data?.caskType : "Not Available"}
                </p>
              </div>
              <div>
                <p className="r_font-16 d-md-block d-none">AGE</p>
                <p className="r_font-28 d-md-block d-none">
                  {data?.brandAge ? data?.brandAge : "Not Available"}
                </p>
              </div>
              <div>
                <p className="r_font-16">Brand</p>
                <p className="r_font-28">
                  LOREUM IPSUM <span className="light_color">(54)</span>
                </p>
              </div>
            </div>
            <p className="review_para mb-0">
              Brandy distilled in a delimited region within France from specific
              grapes. Must be double-distilled to no higher than 72% ABV.
              Required to be aged in oak at least 2 years. Also, if labeled as
              the following, minimum aging is: VS-2 years, VSOP-4 years,
              Napoleon 6 years, XO-10 years (as of April 2018).
            </p>
          </div>
        );
      case "USER REVIEW":
        return (
          <div>
            <p>User Review Content</p>
          </div>
        );
      case "RELEASE LEGEND":
        return (
          <div className="">
            <div className="row">
              <div className="col-md-5 gallery-item mb-md-0">
                <div className="first-image">
                  <img
                    src={Review_rec_img1}
                    className="img-fluid"
                    alt="First Image"
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-6 gallery-item pe-md-0">
                    <img
                      src={Review_rec_img2}
                      className="img-fluid"
                      alt="Second Image 1"
                    />
                  </div>
                  <div className="col-6 gallery-item pe-md-0">
                    <img
                      src={Review_rec_img3}
                      className="img-fluid"
                      alt="Second Image 2"
                    />
                  </div>
                  <div className="col-6 gallery-item pe-md-0">
                    <img
                      src={Review_rec_img4}
                      className="img-fluid"
                      alt="Second Image 3"
                    />
                  </div>
                  <div className="col-6 gallery-item pe-md-0">
                    <img
                      src={Review_rec_img5}
                      className="img-fluid"
                      alt="Second Image 4"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="RELEASE_LEGEND_para mt-md-4">
              <p>
                Bache-Gabrielsen is a revered cognac house started in 1905. The
                “Natur & Eleganse” line is supposed to be a break from their
                classical line signified with an non-traditional, short, squat
                bottle and a flavor profile that highlights “no-nonsense,"
                “frank and fruity characters.” The four-plus year old brandy is
                all ugni blanc sourced from vines in mostly the Fins Bois, but
                also the Petite Champagne and Grande Champagne crus.
              </p>

              <p>
                Bache-Gabrielsen is a revered cognac house started in 1905. The
                “Natur & Eleganse” line is supposed to be a break from their
                classical line signified with an non-traditional, short, squat
                bottle and a flavor profile that highlights “no-nonsense,"
                “frank and fruity characters.” The four-plus year old brandy is
                all ugni blanc sourced from vines in mostly the Fins Bois, but
                also the Petite Champagne and Grande Champagne crus.
              </p>
            </div>
          </div>
        );
      case "DISTILLERY INFO":
        return (
          <div className="p-md-4">
            <div className="gallery-container">
              <div className="row">
                <div className="col-md-5 gallery-item mb-md-0">
                  <div className="first-image">
                    <img
                      src={Review_rec_img1}
                      className="img-fluid"
                      alt="First Image"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-6 gallery-item pe-md-0">
                      <img
                        src={Review_rec_img1}
                        className="img-fluid"
                        alt="Second Image 1"
                      />
                    </div>
                    <div className="col-6 gallery-item pe-md-0">
                      <img
                        src={Review_rec_img1}
                        className="img-fluid"
                        alt="Second Image 2"
                      />
                    </div>
                    <div className="col-6 gallery-item pe-md-0">
                      <img
                        src={Review_rec_img1}
                        className="img-fluid"
                        alt="Second Image 3"
                      />
                    </div>
                    <div className="col-6 gallery-item pe-md-0">
                      <img
                        src={Review_rec_img1}
                        className="img-fluid"
                        alt="Second Image 4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row my-2 c_other_info">
              <div className="col-md-10">
                <div className="row me-0 c_other_info_box">
                  <div className="col-lg-4 col-6 my-2">
                    <h6 className="text-scene mb-md-1 mb-0">Country</h6>
                    <span className="text-white span_text">Lorem ipsum</span>
                  </div>
                  <div className="col-lg-4 col-6 my-2">
                    <h6 className="text-scene mb-md-1 mb-0">Region</h6>
                    <ul className="text-white d-flex ps-0 my-2 list-unstyled">
                      <li className="position-relative span_text">
                        • Region 1
                      </li>
                      <li className="position-relative ms-2 span_text">
                        • Region 2
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-6 my-2">
                    <h6 className="text-scene mb-md-1 mb-0">Production type</h6>
                    <span className="text-white span_text">Lorem ipsum</span>
                  </div>
                  <div className="col-lg-4 col-6 my-2">
                    <h6 className="text-scene mb-md-1 mb-0">Releases amount</h6>
                    <span className="text-white span_text">Lorem ipsum</span>
                  </div>
                  <div className="col-lg-4 col-6 my-2">
                    <h6 className="text-scene mb-md-1 mb-0">Brands Amount</h6>
                    <span className="text-white span_text">Lorem ipsum</span>
                  </div>
                  <div className="col-lg-4 col-6 my-2">
                    <h6 className="text-scene mb-md-1 mb-0">
                      Popularity on GWG
                    </h6>
                    <span className="text-white span_text">Lorem ipsum</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row me-0 d_other_info_box">
              <div className="col-md-12 my-2">
                <p className="d_other_info_desc text-white">
                  Bache-Gabrielsen is a revered cognac house started in 1905.
                  The “Natur & Eleganse” line is supposed to be a break from
                  their classical line signified with a non-traditional, short,
                  squat bottle and a flavor profile that highlights
                  “no-nonsense," “frank and fruity characters.” The four-plus
                  year old brandy is all ugni blanc sourced from vines in mostly
                  the Fins Bois, but also the Petite Champagne and Grande
                  Champagne crus.
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="two_sections">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div
                className="review mt-md-5 mt-3 p-2"
                id="details_global_release"
              >
                <div className="d-flex justify-content-between align-items-center p-2 r_border_bottom">
                  <ul className="nav nav_review">
                    <li className="nav-item  nav_heading">
                      <span
                        className={`px-2  ${
                          activeTab === "DETAILS" ? "active text-white" : ""
                        }`}
                        // style={{ color: 'white'}}
                        onClick={() => setActiveTab("DETAILS")}
                      >
                        DETAILS
                      </span>
                    </li>
                    <li className="nav-item nav_heading">
                      <span
                        className={`px-2 text-nowrap ${
                          activeTab === "USER REVIEW" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("USER REVIEW")}
                      >
                        USER REVIEW
                      </span>
                    </li>
                    <li className="nav-item nav_heading">
                      <span
                        className={`px-2 text-nowrap ${
                          activeTab === "RELEASE LEGEND" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("RELEASE LEGEND")}
                      >
                        RELEASE LEGEND
                      </span>
                    </li>
                    <li className="nav-item nav_heading">
                      <span
                        className={`px-2 text-nowrap ${
                          activeTab === "DISTILLERY INFO" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("DISTILLERY INFO")}
                      >
                        DISTILLERY INFO
                      </span>
                    </li>
                  </ul>
                  <div className="nav_btn d-md-block d-none">
                    <span className="text-decoration-none text-white review_btn light_clr d-inline-block p-2">
                      BRAND STORY
                    </span>
                  </div>
                </div>
                <div className="review_rating m-md-4 m-2">
                  {renderContent()}
                </div>
              </div>
              <div className="d-md-none d-block">
                <ReviewBtn />
              </div>
              <Notes />
              <Flavours data={data} />
            </div>
            <div className="col-lg-4 col-md-12 mt-md-5 mt-3 p-3 pt-0">
              <div className="ms-md-4">
                <div className="d-md-block d-none">
                  <ReviewBtn />
                </div>
                <div className="">
                  <BrandIntro />
                </div>
                <div className="brand_head2 mt-5 mb-4">YOU MAY ALSO LIKE</div>
                <div className="">
                  <SideCards />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
