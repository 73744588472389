import { FETCH_DISTILLERY_REQUEST, FETCH_DISTILLERY_SUCCESS, FETCH_DISTILLERY_FAILURE, FETCH_DISTILLERIES_BY_COMPANY_REQUEST, FETCH_DISTILLERIES_BY_COMPANY_SUCCESS, FETCH_DISTILLERIES_BY_COMPANY_FAILURE, FETCH_OTHER_DISLITTERY_SUCCESS, FETCH_OTHER_DISLITTERY_FAILURE, FETCH_OTHER_DISLITTERY_REQUEST } from './actionTypes';

export const fetchDistilleryRequest = () => ({
  type: FETCH_DISTILLERY_REQUEST,
});

export const fetchDistillerySuccess = (data) => ({
  type: FETCH_DISTILLERY_SUCCESS,
  payload: data,
});

export const fetchDistilleryFailure = (error) => ({
  type: FETCH_DISTILLERY_FAILURE,
  payload: error,
});


export const fetchDistilleriesByCompanyRequest = () => ({
  type: FETCH_DISTILLERIES_BY_COMPANY_REQUEST,
});

export const fetchDistilleriesByCompanySuccess = (data) => ({
  type: FETCH_DISTILLERIES_BY_COMPANY_SUCCESS,
  payload: data,
});

export const fetchDistilleriesByCompanyFailure = (error) => ({
  type: FETCH_DISTILLERIES_BY_COMPANY_FAILURE,
  payload: error,
});



export const fetchOtherDistilleryRequest = () => ({
  type: FETCH_OTHER_DISLITTERY_REQUEST,
});

export const fetchOtherDistillerySuccess = (data) => ({
  type: FETCH_OTHER_DISLITTERY_SUCCESS,
  payload: data,
});

export const fetchOtherDistilleryFailure = (error) => ({
  type: FETCH_OTHER_DISLITTERY_FAILURE,
  payload: error,
});
