import React, { useEffect } from 'react'
export default
 function FormSubFooter(props) {


    return (
        <section className="ct_form_footer" style={{position:"fixed",bottom:0,width:'100%',    zIndex: '100'}}>

            <div className="container">
                <div className="row justify-content-center">
                    <div className='col-xl-2 col-lg-2 col-md-2 col-sm-0 col-xs-0'> </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 mx-auto d-flex justify-content-center">
                        <div className="ct_head_right">
                            
                            <button onClick={props.onCancel} className="ct_outline_btn ct_outline_btn_white">
                                Cancel
                            </button>
                            {/* <button onClick={props.onSaveAsDraft} className="ct_outline_btn ct_outline_btn_white">
                                Save Draft
                            </button> */}
                            {props.currentPage > 1 &&
                                <button onClick={props.onPrevious} className="ct_outline_btn ct_outline_btn_white">
                                    Previous
                                </button>
                            }
                            {props.currentPage!=props.totalPages && 
                            <button onClick={()=>props.onProceed()} className="ct_custom_bg_btn ct_custom_btn_white">
                                Proceed
                            </button>
 }
                            {props.currentPage==props.totalPages && 
                            <button onClick={props.onPublish} className="ct_custom_bg_btn ct_custom_btn_white">
                                Publish
                            </button>
}
                        </div>
                    </div>
                    <div className='col-xl-2 col-lg-2 col-md-2 col-sm-0 col-xs-0'> </div>

                </div>
            </div>
        </section>
    )
}