import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../pages/Brand/Brand.css";
import { Navigation } from "swiper/modules";
import upload_img_white from "../assets/images/upload_img_white.png";
import noImage from "../assets/images/noImage.jpeg";
const FileUpload = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState([]);
  const groupItems = (items, itemsPerGroup) => {
    const groupedItems = [];
    if (items?.length) {
      for (let i = 0; i < items.length; i += itemsPerGroup) {
        groupedItems.push(items.slice(i, i + itemsPerGroup));
      }
    }
    return groupedItems;
  };
  const [GalleryItems,setGalleryItems]=useState([])
  useEffect(() => {
    if (props.img) {
      if (props.multiple) {
        setPreviewUrl(props.img);
        setGalleryItems(groupItems(props.img, 3));
      } else {
        let images = [props.img];
        setPreviewUrl(images);
      }
    }
  }, [props.img]);
  const handleFileChange = (event) => {
    // const file = event.target.files[0];
    // setSelectedFile(file)
    // if (file) {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setPreviewUrl([reader.result]);
    //     };
    //     reader.readAsDataURL(file);
    // } else {
    //     setPreviewUrl([]);
    // }

    props.uploadedImage(event.target.files[0]);
  };
const handleRemove=(groupIndex,index)=>{

}
  return (
    <div className="ct_full_img_upload p-1">
      <label htmlFor={props.imageTitle} className="mb-0 w-100">
        {(!props.multiple || (props.multiple && !previewUrl?.length)) && (
          <input
            type="file"
            id={props.imageTitle}
            className="d-none"
            accept="image/*"
            onChange={(e) => handleFileChange(e)}
          />
        )}

        {!props.multiple && previewUrl?.length ? (
          <img src={previewUrl[0]} className="w-100 p-2" />
        ) : (
          ""
        )}
        {props.multiple && previewUrl?.length ? (
          // <Swiper
          //   spaceBetween={50}
          //   slidesPerView="auto"
          //   pagination={{
          //     type: "fraction",
          //   }}
          //   navigation={true}
          //   modules={[Pagination]}
          // >
          //   {previewUrl.map((image, index) => (
          //     <SwiperSlide
          //       key={index}
          //       style={{ height: "200px", width: "200px" }}
          //     >
          //       <img
          //         src={image}
          //         className="p-2"
          //         alt={`Slide ${index}`}
          //         style={{ width: "100%", height: "100%", objectFit: "fill" }}
          //       />
          //     </SwiperSlide>
          //   ))}
          // </Swiper>
          <div className="container my-4 position-relative">
          <div
          id="galleryCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <button
            style={{
              background: "#BA9775",
              marginLeft: "-.5rem",
              marginTop: "5rem",
              height: "1.5rem",
              width: "1rem",
            }}
            className="carousel-control-prev"
            type="button"
            data-bs-target="#galleryCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <div className="carousel-inner">
            {GalleryItems.map((itemGroup, index) => (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
              >
                <div className="row">
                  {itemGroup.map((item, subIndex) => (
                    <div className="col-md-4" key={subIndex}>
                      <div className="scroll-item file-upload-sroller">
                        <img
                          src={
                            item
                              ? item
                              : noImage
                          }
                          className="d-block w-100 img-fluid"
                          style={{ height: "180px", marginBottom: "11px" }}
                          alt={item.altText}
                        />
                         <button
      className="btn btn-danger"
      style={{
        position: "absolute",
        top: "5px",
        right: "5px",
        display: "none" // Initially hide the button
      }}
      onClick={() => handleRemove(index,subIndex)} // Define handleRemove function to remove the item
    >
      Remove
    </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            </div>
          <button
            style={{
              background: "#BA9775",
              marginRight: "-.5rem",
              marginTop: "5rem",
              height: "1.5rem",
              width: "1rem",
            }}
            className="carousel-control-next"
            type="button"
            data-bs-target="#galleryCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        </div>
        ) : (
          ""
        )}
        {previewUrl?.length == 0 ? (
          <>
            {" "}
            <img style={{ width: "1.2rem",    display: 'flex',margin: 'auto' }} src={upload_img_white} />
            <p className="mb-0 cursor-pointer" style={{ opacity: "0.5" }}>
              {props.imageTitle}
            </p>
          </>
        ) : (
          ""
        )}
      </label>
    </div>
  );
};
export default FileUpload;