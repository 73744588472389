import {
  FETCH_B2C_BRAND_FAILURE,
  FETCH_B2C_BRAND_REQUEST,
  FETCH_B2C_BRAND_SUCCESS,
  FETCH_B2C_DISTILLERY_FAILURE,
  FETCH_B2C_DISTILLERY_REQUEST,
  FETCH_B2C_DISTILLERY_SUCCESS,
  FETCH_B2C_RELEASE_FAILURE,
  FETCH_B2C_RELEASE_REQUEST,
  FETCH_B2C_RELEASE_SUCCESS,
  FETCH_B2C_BRAND_BY_ID_REQUEST,
  FETCH_B2C_BRAND_BY_ID_SUCCESS,
  FETCH_B2C_BRAND_BY_ID_FAILURE,
  FETCH_B2C_COMPANY_LANDINGPAGE_FAILURE,
  FETCH_B2C_COMPANY_LANDINGPAGE_SUCCESS,
  FETCH_B2C_COMPANY_LANDINGPAGE_REQUEST,
} from "../actions/b2c";
const initialState = {
  brands: {
    loading: false,
    error: null,
    data: [],
  },
  distilleries: {
    loading: false,
    error: null,
    data: [],
  },
  releases: {
    loading: false,
    error: null,
    data: [],
  },
  brandById: {
    loading: false,
    error: null,
    data: null,
  },
  companyData: {
    loading: false,
    error: null,
    data: null,
  },
};
const b2cReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_B2C_BRAND_REQUEST:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: true,
          error: null,
        },
      };
    case FETCH_B2C_BRAND_SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_B2C_BRAND_FAILURE:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_B2C_DISTILLERY_REQUEST:
      return {
        ...state,
        distilleries: {
          ...state.distilleries,
          loading: true,
          error: null,
        },
      };
    case FETCH_B2C_DISTILLERY_SUCCESS:
      return {
        ...state,
        distilleries: {
          ...state.distilleries,
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_B2C_DISTILLERY_FAILURE:
      return {
        ...state,
        distilleries: {
          ...state.distilleries,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_B2C_RELEASE_REQUEST:
      return {
        ...state,
        releases: {
          ...state.releases,
          loading: true,
          error: null,
        },
      };
    case FETCH_B2C_RELEASE_SUCCESS:
      return {
        ...state,
        releases: {
          ...state.releases,
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_B2C_RELEASE_FAILURE:
      return {
        ...state,
        releases: {
          ...state.releases,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_B2C_BRAND_BY_ID_REQUEST:
      return {
        ...state,
        brandById: {
          ...state.brandById,
          loading: true,
          error: null,
        },
      };
    case FETCH_B2C_BRAND_BY_ID_SUCCESS:
      return {
        ...state,
        brandById: {
          ...state.brandById,
          loading: false,
          data: action.payload,
        },
      };
    case FETCH_B2C_BRAND_BY_ID_FAILURE:
      return {
        ...state,
        brandById: {
          ...state.brandById,
          loading: false,
          error: action.payload,
        },
      };

   case FETCH_B2C_COMPANY_LANDINGPAGE_REQUEST:
        return {
          ...state,
          companyData: {
            ...state.companyData,
            loading: true,
            error: null,
          },
        };
      case FETCH_B2C_COMPANY_LANDINGPAGE_SUCCESS:
        return {
          ...state,
          companyData: {
            ...state.companyData,
            loading: false,
            data: action.payload,
          },
        };
      case FETCH_B2C_COMPANY_LANDINGPAGE_FAILURE:
        return {
          ...state,
          companyData: {
            ...state.companyData,
            loading: false,
            error: action.payload,
          },
        };
     
    default:
      return state;
  }

  
};
export default b2cReducer;
