import React from 'react';
import './HeaderFooter.css';
import img from '../assets/images/Group 46.png';
import img2 from '../assets/images/Group 47.png';
import img3 from '../assets/images/Group 48.png';
import img4 from '../assets/images/Group 49.png';
import img5 from '../assets/images/Group 50.png';
import img6 from '../assets/images/Rectangle 34624265.png';

const Footer = () => {
  const initialImageData = [
    { url: 'https://twitter.com/sampleaccount', alt: 'Twitter', img: img },
    { url: 'https://facebook.com/sampleaccount', alt: 'Facebook', img: img2 },
    { url: 'https://instagram.com/sampleaccount', alt: 'Instagram', img: img3 },
    { url: 'https://pinterest.com/sampleaccount', alt: 'Pinterest', img: img6 },
    { url: 'https://youtube.com/sampleaccount', alt: 'YouTube', img: img4 },
    { url: 'https://linkedin.com/sampleaccount', alt: 'LinkedIn', img: img5 },
   
  ];

  return (
    <section className="footer_bg_img">
      <div className="bg_footer">
        <footer className="py-3">
          <div className="d-flex justify-content-center flex-column align-items-center">
            <h3 className="footer_head text-white">Global Guide</h3>
            <ul className="nav pb-3 mb-2 justify-content-center align-items-center">
              {initialImageData.map((item, index) => (
                <li key={index} className="nav-item">
                  <a href={item.url} target="_blank" rel="noopener noreferrer" className="nav-link px-2 text-white groupimgs">
                    <img src={item.img} alt={item.alt} />
                  </a>
                </li>
              ))}
            </ul>
            <ul className="nav pb-2  justify-content-center mx-sm-0 mx-3">
              <li className="nav-item"><p  className="nav-link px-2 text-white font_16 hover:cursor-pointer">Blog</p></li>
              <li className="nav-item text-white py-2">|</li>
              <li className="nav-item"><p  className="nav-link px-2 text-white hover:cursor-pointer">Privacy</p></li>
              <li className="nav-item text-white py-2">|</li>
              <li className="nav-item"><p  className="nav-link px-2 text-white hover:cursor-pointer">FAQs</p></li>
              <li className="nav-item text-white py-2">|</li>
              <li className="nav-item"><p  className="nav-link px-2 text-white hover:cursor-pointer">Contact</p></li>
              <li className="nav-item text-white py-2">|</li>
              <li className="nav-item"><p  className="nav-link px-2 text-white hover:cursor-pointer">Sitemap</p></li>
              <li className="nav-item text-white py-2">|</li>
              <li className="nav-item"><p  className="nav-link px-2 text-white hover:cursor-pointer">Terms & condition</p></li>
            </ul>
          </div>
          <p className="text-center text-white">Copyright &copy; 2023. All rights reserved</p>
        </footer>
      </div>
    </section>
  );
}

export default Footer;
