import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateLayout from "../layouts/PrivateLayout";

const ProtectedRoute = ({ children,subHeader,headerConfig }) => {
  const isAuthenticated = localStorage.getItem('authToken')
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  
  return (
    <>
      <PrivateLayout subHeader={subHeader} headerConfig={headerConfig} >
      {children}
      </PrivateLayout>
    </>
  )
};

export default ProtectedRoute;
