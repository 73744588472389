import React from "react";

const AddSvg = () => {
  return (
    <div className="ct_plus_position_center" >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11" cy="11" r="10.5" stroke="white" />
        <path
          d="M10.3212 15V7H11.6788V15H10.3212ZM7 11.6788V10.3212H15V11.6788H7Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default AddSvg;
