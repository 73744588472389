import React, {Suspense} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import B2CPublicRoutes from "./routes/b2c/B2CPublicRoutes";
import ProtectedRoute from "./routes/PrivateRoute";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import store, {persistor} from "./store";
import EditCompany from "./pages/Company/EditCompany";
import Loader from "./components/Loader/Loader";
import PublicRoute from "./routes/PublicRoute";
import EditRelease from "./pages/Release/EditRelease";
import {ToastContainer} from "react-bootstrap";

const DistilleryList = React.lazy(() => import("./pages/Distillery/DistilleryList"));


//b2b Routes
const Brand = React.lazy(() => import("./pages/Brand/Brand"));
const GlobalGuide = React.lazy(() => import("./pages/GlobalGuide/GlobalGuide"));
const Company = React.lazy(() => import("./pages/Company/Company"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const AddDistillery = React.lazy(() => import('./pages/Distillery/AddDistillery'));
const AddBrand = React.lazy(() => import('./pages/Brand/AddBrand'));
const AddRelease = React.lazy(() => import('./pages/Release/AddRelease'));
const ReleaseDetail = React.lazy(() => import('./pages/RealiseDetail/Detail'));
const Release = React.lazy(() => import('./pages/Release/Release'));
const LandingPage = React.lazy(() => import('./pages/Landing/LandingPage'));
const ViewRelease = React.lazy(() => import('./pages/Release/ViewRelease'));

const AddBrandsToDistillery = React.lazy(() => import('./pages/Distillery/AddBrandsToDistillery'));
const SuccessDistilleryPage = React.lazy(() => import('./pages/Distillery/SuccessDistilleryPage'));
const AddReleaseToDistillery = React.lazy(() => import('./pages/Distillery/AddReleaseToDistillery'));
////b2c Routes
const b2cBrand = React.lazy(() => import("./pages/b2c/B2CBrand/B2CBrand"));
const b2cRelease = React.lazy(() => import("./pages/b2c/B2CRelease/B2CRelease"));
const b2cDistelleries = React.lazy(() => import("./pages/b2c/B2CDistelleries/B2CDistelleries"));
const b2cDetails = React.lazy(() => import("./pages/RealiseDetail/Detail"));


const b2cheaderConfig = [
  {"name": "Brands", "link": "/b2c/brand"},
  {"name": "Releases", "link": "/b2c/release"},
  {"name": "Distilleries", "link": "/b2c/distelleries"},
  {"name": "Merchants", "link": "#"},
  {"name": "Newsfeed", "link": "#"},
  {"name": "History", "link": "#"},
  {"name": "Collections", "link": "#"}
]
const b2cBrandSubHeader = {
  subHeader: [
    {"name": "BRANDS", "link": "#"},
  ],
}

const b2cReleaseSubHeader = {
  subHeader: [
    {"name": "RELEASES", "link": "#"},
  ],
}
const b2cDistilleriesSubHeader = {
  subHeader: [
    {"name": "DISTELLERIES", "link": "/"},
  ],
}

const b2cPublicRoutes = [
  {path: "/b2c/brand", component: b2cBrand, subheader: []},
  {path: "/b2c/detail/:id", component: b2cDetails, subheader: []},
  {path: "/b2c/release", component: b2cRelease, subheader: []},
  {path: "/b2c/distelleries", component: b2cDistelleries, subheader: []},
];


const publicRoutes = [
  {path: "/landingPage", component: LandingPage},
  {path: "/signin", component: Login},
  {path: "/", component: GlobalGuide, subheader: []},
];
const realeaseSubHeader = {
  subHeader: [
    {"name": "Releases", "link": "/release"},
    {"name": "Drafts", "link": "#"},
    {"name": "Archived", "link": "#"},
    {"name": "Pending Review", "link": "#"}
  ],
  subHeaderButtons: [
    {"name": "Import Catalog Data", "link": "#"},
    {"name": "New Release", "link": "/add-release"},
  ]
}

const protectedRoutes = [
  {path: "/company", component: Company, subheader: []},
  {path: '/add-brand', component: AddBrand, subheader: []},
  {path: '/add-distillery', component: AddDistillery, subheader: []},
  {path: '/company/:id', component: EditCompany, subheader: []},
  {path: '/release-detail/:id', component: ReleaseDetail, subheader: []},
  {path: '/release', component: Release, subheader: realeaseSubHeader},
  {path: '/brand', component: Brand, subheader: []},
  {path: '/add-release', component: AddRelease, subheader: []},
  {path: '/distillery', component: DistilleryList, subheader: []},
  {path: '/distillery/success-page', component: SuccessDistilleryPage, subheader: []},
  {path: '/distillery/add-brand', component: AddBrandsToDistillery, subheader: []},
  {path: '/distillery/add-release', component: AddReleaseToDistillery, subheader: []},
  {path: '/view-release/:id', component: ViewRelease, subheader: []},
  {path: '/edit-release/:id', component: EditRelease, subheader: []},
]

const headerConfig = [
  {"name": "Home", "link": "#"},
  {"name": "Brands", "link": "/brand"},
  {"name": "Company", "link": "/company"},
  {"name": "Distilleries", "link": "/distillery"},
  {"name": "Releases", "link": "/release"},
  {"name": "News & Events", "link": "#"},
  {"name": "Reviews", "link": "#"}
]

const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer/>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Suspense fallback={<Loader/>}>
            <Routes>
              {b2cPublicRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <B2CPublicRoutes b2cheaderConfig={b2cheaderConfig} subHeader={route.subheader}>
                      <route.component/>
                    </B2CPublicRoutes>
                  }
                />
              ))}
              {publicRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <PublicRoute>
                      <route.component/>
                    </PublicRoute>
                  }
                />
              ))}
              {protectedRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <ProtectedRoute headerConfig={headerConfig} subHeader={route.subheader}>
                      <route.component/>
                    </ProtectedRoute>
                  }
                />
              ))}
              <Route path="*" element={<NotFound/>}/>
            </Routes>
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
