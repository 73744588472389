import React, { useEffect } from 'react'
import Header from '../components/Header'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import GlobalGuide from '../pages/b2c/ReleaseDetail/GlobalGuide'
import BrandComp from '../components/ListPages/BrandCard/BrandComp'

const PublicRoute = ({children}) => {
  const navigate = useNavigate()
  const location = useLocation();
  const token = localStorage.getItem('authToken')
  useEffect(() => {
    if(token){
      navigate('/company')
    }
  }, [token])
  const listToHideFooter = ['/'];
  const listToHideHeader = ['/'];
  const check = (type) => {
    switch (type) {
      case 'FOOTER': return !listToHideFooter.some((e)=>e==location.pathname);
      case 'HEADER': return !listToHideHeader.some((e)=>e==location.pathname);
    }

  }
  return (
    <>
    {check('HEADER') && <Header />}
    {children}
    {check('FOOTER') && <Footer />}
    </>
  )
}

export default PublicRoute