export const FETCH_B2C_BRAND_REQUEST = 'FETCH_B2C_BRAND_REQUEST';
export const FETCH_B2C_BRAND_SUCCESS = 'FETCH_B2C_BRAND_SUCCESS';
export const FETCH_B2C_BRAND_FAILURE = 'FETCH_B2C_BRAND_FAILURE';

export const FETCH_B2C_BRAND_BY_ID_REQUEST = 'FETCH_B2C_BRAND_BY_ID_REQUEST';
export const FETCH_B2C_BRAND_BY_ID_SUCCESS = 'FETCH_B2C_BRAND_BY_ID_SUCCESS';
export const FETCH_B2C_BRAND_BY_ID_FAILURE = 'FETCH_B2C_BRAND_BY_ID_FAILURE';


export const FETCH_B2C_DISTILLERY_REQUEST = 'FETCH_B2C_DISTILLERY_REQUEST';
export const FETCH_B2C_DISTILLERY_SUCCESS = 'FETCH_B2C_DISTILLERY_SUCCESS';
export const FETCH_B2C_DISTILLERY_FAILURE = 'FETCH_B2C_DISTILLERY_FAILURE';

export const FETCH_B2C_RELEASE_REQUEST = 'FETCH_B2C_RELEASE_REQUEST';
export const FETCH_B2C_RELEASE_SUCCESS = 'FETCH_B2C_RELEASE_SUCCESS';
export const FETCH_B2C_RELEASE_FAILURE = 'FETCH_B2C_RELEASE_FAILURE';

export const FETCH_B2C_COMPANY_LANDINGPAGE_REQUEST = 'FETCH_B2C_COMPANY_LANDINGPAGE_REQUEST';
export const FETCH_B2C_COMPANY_LANDINGPAGE_SUCCESS = 'FETCH_B2C_COMPANY_LANDINGPAGE_SUCCESS';
export const FETCH_B2C_COMPANY_LANDINGPAGE_FAILURE = 'FETCH_B2C_COMPANY_LANDINGPAGE_FAILURE';

