import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import NoImage from "../../../../assets/images/noImage.jpeg";
import './Corosel.css';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import slide1 from "../../../../assets/img/slide1.png";
import slide2 from "../../../../assets/img/slide2.png";
import slide3 from "../../../../assets/img/slide3.png";
import prev_box from "../../../../assets/img/prev_box.png";
import next_box from "../../../../assets/img/next_box.png";

export default function Corosel({ data }) {
    return (
        <>
            <div className="container">
                <div className="swiper_position">
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        spaceBetween={25}
                        loop={true}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}

                        navigation={{ nextEl: '.custom-swiper-button-next', prevEl: '.custom-swiper-button-prev' }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {data?.images?.map((image, index) => (
                            <SwiperSlide key={index}>
                                <img
                                    src={
                                        image
                                            ? `${process.env.REACT_APP_PHOTOS_URL}/${image}`
                                            : NoImage
                                    }
                                    alt="Cover Image"
                                />
                            </SwiperSlide>
                        ))}
                        {/* <SwiperSlide>
                            <img src={slide1} alt="Slide 1" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide2} alt="Slide 2" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide3} alt="Slide 3" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide2} alt="Slide 2" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={slide1} alt="Slide 1" />
                        </SwiperSlide> */}
                    </Swiper>
                    <div className='d-md-block d-none'>
                        <div className="custom-swiper-button-prev ">
                            <img src={prev_box} alt='prev_box' />
                        </div>
                        <div className="custom-swiper-button-next">
                            <img src={next_box} alt='next_box' />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
