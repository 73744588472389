import React, { useEffect, useState } from "react";
import "./EditCompany.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editCompanyInfo,
  getCompanyInfoById,
} from "../../store/actions/company";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddSvg from "../../assets/svg/AddSvg";
import Loader from "../../components/Loader/Loader";
import SuccessModel from "../../components/SuccessModel";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const EditCompany = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { companyInfo } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [modalShow, setmodalShow] = useState(false);

  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    if (user) {
      const { companyId } = user;
      dispatch(getCompanyInfoById(companyId));
    }
  }, [user, dispatch]);

  const validationSchema = Yup.object({
    contactNumber: Yup.string()
      .matches(
        /^\+?\d{10,15}$/,
        "Contact Number must be between 10 to 15 digits and may start with a '+'"
      )
      .required("Contact Number is required"),
    address: Yup.string().required("Address is required"),
    name: Yup.string().required("Name is required"),
    ean: Yup.string()
      .matches(/^\d{13}$/, "EAN must be exactly 13 digits")
      .required("EAN is required"),
    yearEstablished: Yup.number()
      .min(1900, "Year Established must be after 1900")
      .max(new Date().getFullYear(), `Year Established cannot be in the future`)
      .required("Year Established is required"),
    country: Yup.string().required("Country is required"),
    region: Yup.string().required("Region is required"),
    representativeName: Yup.string().required(
      "Company Representative name is required"
    ),
    representativeContact: Yup.string()
      .matches(
        /^\+?\d{10,15}$/,
        "Company Representative contact must be between 10 to 15 digits and may start with a '+'"
      )
      .required("Company Representative contact is required"),
    contactPersonName: Yup.string().required("Contact Person Name is required"),
    contactPersonInfo: Yup.string().required("Contact Person Info is required"),
    legend: Yup.string().required("Company Legend is required"),
    facebookLink: Yup.string().url("Invalid URL format for Facebook"),
    facebookLink: Yup.string().url("Invalid URL format for Instagram"),
    xLink: Yup.string().url("Invalid URL format for X (Twitter)"),
  });

  const formik = useFormik({
    initialValues: {
      ean: companyInfo?.ean || "",
      contactNumber: companyInfo?.contactNumber || "",
      address: companyInfo?.address || "",
      name: companyInfo?.name || "",
      yearEstablished: companyInfo?.yearEstablished || "",
      country: companyInfo?.country || "",
      region: companyInfo?.region || "",
      representativeName: companyInfo?.representativeName || "",
      representativeContact: companyInfo?.representativeContact || "",
      contactPersonName: companyInfo?.contactPersonName || "",
      contactPersonInfo: companyInfo?.contactPersonInfo || "",
      legend: companyInfo?.legend || "",
      facebookLink: companyInfo?.facebookLink || "",
      facebookLink: companyInfo?.instagramLink || "",
      xLink: companyInfo?.xLink || "",
      address: "Madhya Pradesh",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("logo", values?.logo);
      formData.append("name", values?.name);
      formData.append("address", values?.address);
      formData.append("country", values?.country);
      formData.append("region", values?.region);
      formData.append("ean", values?.ean);
      formData.append("yearEstablished", values?.yearEstablished);
      formData.append("contactNumber", values?.contactNumber);
      formData.append("legend", values?.legend);
      formData.append("representativeName", values?.representativeName);
      formData.append("representativeContact", values?.representativeContact);
      formData.append("contactPersonName", values?.contactPersonName);
      formData.append("facebookLink", values?.facebookLink);
      formData.append("instagramLink", values?.facebookLink);
      formData.append("xLink", values?.xLink);
      formData.append("contactPersonName", values?.contactPersonName);
      console.log("values", formData);

      setloader(true);
      setmodalShow(true);
      dispatch(editCompanyInfo(id, values));
      toast.success("Updated");
      setTimeout(() => {
        navigate("/company");
      }, 1000);
      setloader(false);
    },
  });

  useEffect(() => {
    // Check if form values have changedx
    const isDirty =
      Object.keys(formik.values).some(
        (key) => formik.values[key] !== formik.initialValues[key]
      ) || formik.dirty;

    // Update state variable
    setIsFormDirty(isDirty);
  }, [formik.values, formik.dirty, formik.initialValues]);

  // if(loader){
  //   return <Loader/>
  // }

  return (
    <div className="row" style={{ background: "black" }}>
      <ToastContainer />
      {console.log("formik", formik.errors)}
      <div className="col-md-8 mx-auto">
        <div className="ct_dark_grey_form_bg" id="step_1">
          <div className="ct_form_head">
            <div className="ct_head_left">
              <h4 className="ct_Cormorant_font ct_fs_24 text-white mb-0">
                Edit Company
              </h4>
            </div>
            <div className="ct_head_right">
              <Link to="/company">
                <button className="ct_outline_btn">Cancel</button>
              </Link>
              <button
                style={{ opacity: "10" }}
                type="submit"
                onClick={()=>formik.handleSubmit()}
                className="ct_custom_bg_btn"
                disabled={!isFormDirty}
              >
                Confirm
              </button>
            </div>
          </div>
          <div className="ct_form_data">
            <form onSubmit={formik.handleSubmit}>
              <h6 className="ct_fs_20 ct_Cormorant_font text-white">
                Main Info
              </h6>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Title</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="ct_input"
                      placeholder="Add name of company"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="error text-white">
                        {formik.errors.name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">EAN</label>
                    <input
                      type="text"
                      id="ean"
                      name="ean"
                      className="ct_input"
                      placeholder="Add establishment year"
                      {...formik.getFieldProps("ean")}
                    />
                    {formik.touched.ean && formik.errors.ean && (
                      <div className="error text-white">
                        {formik.errors.ean}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Year Established</label>
                    <input
                      type="text"
                      id="yearEstablished"
                      name="yearEstablished"
                      className="ct_input"
                      placeholder="Add Year Of Established"
                      {...formik.getFieldProps("yearEstablished")}
                    />
                    {formik.touched.yearEstablished &&
                      formik.errors.yearEstablished && (
                        <div className="error text-white">
                          {formik.errors.yearEstablished}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Country</label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      className="ct_input"
                      placeholder="Add country"
                      {...formik.getFieldProps("country")}
                    />
                    {formik.touched.country && formik.errors.country && (
                      <div className="error text-white">
                        {formik.errors.country}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Company Contact Number</label>
                    <input
                      type="text"
                      id="contactNumber"
                      name="contactNumber"
                      className="ct_input"
                      placeholder="Add Company's Contact Number"
                      {...formik.getFieldProps("contactNumber")}
                    />
                    {formik.touched.contactNumber &&
                      formik.errors.contactNumber && (
                        <div className="error text-white">
                          {formik.errors.contactNumber}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Company Address</label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      className="ct_input"
                      placeholder="Add Company's Address"
                      {...formik.getFieldProps("address")}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className="error text-white">
                        {formik.errors.address}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Region</label>
                    <input
                      type="text"
                      id="region"
                      name="region"
                      className="ct_input"
                      placeholder="Add Region"
                      {...formik.getFieldProps("region")}
                    />
                    {formik.touched.region && formik.errors.region && (
                      <div className="error text-white">
                        {formik.errors.region}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Company Representative Name</label>
                    <input
                      type="text"
                      id="representativeName"
                      name="representativeName"
                      className="ct_input"
                      placeholder="Add Company Representative Name"
                      {...formik.getFieldProps("representativeName")}
                    />
                    {formik.touched.representativeName &&
                      formik.errors.representativeName && (
                        <div className="error text-white">
                          {formik.errors.representativeName}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Company Representative Contact</label>
                    <input
                      type="text"
                      id="representativeContact"
                      name="representativeContact"
                      className="ct_input"
                      placeholder="Add Company Representative Contact"
                      {...formik.getFieldProps("representativeContact")}
                    />
                    {formik.touched.representativeContact &&
                      formik.errors.representativeContact && (
                        <div className="error text-white">
                          {formik.errors.representativeContact}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Contact Person Name</label>
                    <input
                      type="text"
                      id="contactPersonName"
                      name="contactPersonName"
                      className="ct_input"
                      placeholder="Add Contact Person Name"
                      {...formik.getFieldProps("contactPersonName")}
                    />
                    {formik.touched.contactPersonName &&
                      formik.errors.contactPersonName && (
                        <div className="error text-white">
                          {formik.errors.contactPersonName}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name">Contact Person Info</label>
                    <input
                      type="text"
                      id="contactPersonName"
                      name="contactPersonName"
                      className="ct_input"
                      placeholder="Add Contact Person Info"
                      {...formik.getFieldProps("contactPersonInfo")}
                    />
                    {formik.touched.contactPersonInfo &&
                      formik.errors.contactPersonInfo && (
                        <div className="error text-white">
                          {formik.errors.contactPersonInfo}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-3">
                    <label htmlFor="legend">Company Legend</label>
                    <textarea
                      id="legend"
                      name="legend"
                      className="ct_input h-auto"
                      rows="4"
                      placeholder="Type here"
                      {...formik.getFieldProps("legend")}
                    />
                    {formik.touched.legend &&
                      formik.errors.legend && (
                        <div className="error text-white">
                          {formik.errors.legend}
                        </div>
                      )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="ct_multiple_links">
                    <div>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="facebookLink"
                          name="facebookLink"
                          placeholder="Company Facebook"
                          className={`ct_round_links_input ${
                            formik.touched.facebookLink && formik.errors.facebookLink
                              ? "border-red-500 border"
                              : ""
                          }`}
                          {...formik.getFieldProps("facebookLink")}
                        />

                        <AddSvg className="ct_plus_position_center" />
                      </div>
                      {formik.touched.facebookLink && formik.errors.facebookLink ? (
                        <div className="error text-white text-sm mt-1">
                          {formik.errors.facebookLink}
                        </div>
                      ) : null}
                    </div>

                    {/* Repeat the same pattern for other input fields */}
                    <div>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="facebookLink"
                          name="facebookLink"
                          placeholder="Company Instagram"
                          className="ct_round_links_input"
                          {...formik.getFieldProps("facebookLink")}
                        />
                        <AddSvg className="ct_plus_position_center" />
                      </div>
                      {formik.touched.facebookLink && formik.errors.facebookLink ? (
                        <div className="error text-white text-sm mt-1">
                          {formik.errors.facebookLink}
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <div className="position-relative">
                        <input
                          type="text"
                          id="xLink"
                          name="xLink"
                          placeholder="Company link"
                          className="ct_round_links_input"
                          {...formik.getFieldProps("xLink")}
                        />
                        <AddSvg className="ct_plus_position_center" />
                      </div>
                      {formik.touched.xLink && formik.errors.xLink ? (
                      <div className="error text-white text-sm mt-1">
                        {formik.errors.xLink}
                      </div>
                    ) : null}
                    </div>
                    {/* <div className="position-relative">
                                            <input
                                                type="text"
                                                id="characteristic"
                                                name="characteristic"
                                                placeholder="Add characteristic"
                                                className="ct_round_links_input"
                                                {...formik.getFieldProps('characteristic')}
                                            />
                                            <img src="img/plus.png" className="ct_plus_position_center" alt="" />
                                        </div> */}
                  </div>
                </div>

                {/* Add other form fields similarly */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
