export const FETCH_BRAND_REQUEST = 'FETCH_BRAND_REQUEST';
export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
export const FETCH_BRAND_FAILURE = 'FETCH_BRAND_FAILURE';

export const FETCH_OTHER_BRAND_REQUEST = 'FETCH_OTHER_BRAND_REQUEST';
export const FETCH_OTHER_BRAND_SUCCESS = 'FETCH_OTHER_BRAND_SUCCESS';
export const FETCH_OTHER_BRAND_FAILURE = 'FETCH_OTHER_BRAND_FAILURE';

export const FETCH_EDIT_BRAND_REQUEST = 'FETCH_EDIT_BRAND_REQUEST';
export const FETCH_EDIT_BRAND_SUCCESS = 'FETCH_EDIT_BRAND_SUCCESS';
export const FETCH_EDIT_BRAND_FAILURE = 'FETCH_EDIT_BRAND_FAILURE';

export const FETCH_ADD_BRAND_REQUEST = 'FETCH_ADD_BRAND_REQUEST';
export const FETCH_ADD_BRAND_SUCCESS = 'FETCH_ADD_BRAND_SUCCESS';
export const FETCH_ADD_BRAND_FAILURE = 'FETCH_ADD_BRAND_FAILURE';
