import { FETCH_ADD_BRAND_FAILURE, FETCH_ADD_BRAND_REQUEST, FETCH_ADD_BRAND_SUCCESS, FETCH_BRAND_FAILURE, FETCH_BRAND_REQUEST, FETCH_BRAND_SUCCESS, FETCH_EDIT_BRAND_FAILURE, FETCH_EDIT_BRAND_REQUEST, FETCH_EDIT_BRAND_SUCCESS, FETCH_OTHER_BRAND_FAILURE, FETCH_OTHER_BRAND_REQUEST, FETCH_OTHER_BRAND_SUCCESS } from "../actions/Brand";

  
  const initialCompanyState = {
    loading: false,
    error: null,
    brands: [],
    otherBrands:[],
    brand:{}
  };
  
  const brandReducer = (state = initialCompanyState, action) => {
    switch (action.type) {
      case FETCH_BRAND_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_BRAND_SUCCESS:
        return {
          ...state,
          loading: false,
          brands: action.payload, 
        };
      case FETCH_BRAND_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case FETCH_OTHER_BRAND_SUCCESS:
          return {
            ...state,
            loading: false,
            otherBrands: action.payload, 
          };
        case FETCH_OTHER_BRAND_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
          case FETCH_OTHER_BRAND_REQUEST:
            return {
              ...state,
              loading: true,
              error: null,
            };

            case FETCH_EDIT_BRAND_REQUEST:
              return {
                ...state,
                loading: true,
                error: null,
              };
            case FETCH_EDIT_BRAND_SUCCESS:
              return {
                ...state,
                loading: false,
                brand: action.payload, 
              };
            case FETCH_EDIT_BRAND_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.payload,
              };


              case FETCH_ADD_BRAND_REQUEST:
                return {
                  ...state,
                  loading: true,
                  error: null,
                };
              case FETCH_ADD_BRAND_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  brand: action.payload, 
                };
              case FETCH_ADD_BRAND_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.payload,
                };
      
      default:
        return state;
    }
  };
  
  export default brandReducer;
  