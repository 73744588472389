import { fetchAllReleasesRequest, fetchAllReleasesSuccess, fetchAllReleasesFailure, fetchOtherReleasesRequest, fetchOtherReleasesSuccess, fetchOtherReleasesFailure, postReleaseFailure, postReleaseSuccess, postReleaseRequest, fetchEditReleasesRequest, fetchEditReleasesSuccess, fetchEditReleasesFailure, fetchReleaseByIdRequest, fetchReleaseByIdSuccess, fetchReleaseByIdFailure, resetReleaseById } from './actions';
import { ALL_RELEASES_ENDPOINT, getAllReleasesUrl, getOtherReleasesUrl, getReleaseByIdUrl, postReleaseUrl } from '../../../services/urlHelper'; // Assuming you have a URL helper function
import { get, post, put } from '../../../services/apiService'; // Assuming you have a service for making GET requests

export const fetchAllReleases = () => async (dispatch) => {
  dispatch(fetchAllReleasesRequest());
  try {
    const response = await get(getAllReleasesUrl());
    dispatch(fetchAllReleasesSuccess(response.data));
  } catch (error) {
    dispatch(fetchAllReleasesFailure(error.message));
  }
};

export const fetchOtherReleases = () => async (dispatch) => {
  dispatch(fetchOtherReleasesRequest());
  try {
    const response = await get(getOtherReleasesUrl());
    dispatch(fetchOtherReleasesSuccess(response.data));
  } catch (error) {
    dispatch(fetchOtherReleasesFailure(error.message));
  }
};

export const postRelease = (releaseData) => async (dispatch) => {
  dispatch(postReleaseRequest());
  try {
    const response = await post(postReleaseUrl(), releaseData);
    dispatch(postReleaseSuccess(response.data));
  } catch (error) {
    dispatch(postReleaseFailure(error.message));
  }
};


export const editRelease = (id,data) => async (dispatch) => {
  dispatch(fetchAllReleasesRequest());
  try {
    const response = await put(`${ALL_RELEASES_ENDPOINT}/${id}`,data);
    dispatch(fetchAllReleasesSuccess(response.data));
  } catch (error) {
    dispatch(fetchAllReleasesFailure(error.message));
  }
};

export const fetchReleaseById = (id) => async (dispatch) => {
  dispatch(fetchReleaseByIdRequest(id));
  try {
    const response = await get(getReleaseByIdUrl(id));
    dispatch(fetchReleaseByIdSuccess(response.data));
  } catch (error) {
    dispatch(fetchReleaseByIdFailure(error.message));
  }
};


export const resetReleaseByIdRequest = () => async (dispatch) => {
  dispatch(resetReleaseById());
};