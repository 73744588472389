import { GET_COMPANY_INFO_REQUEST, GET_COMPANY_INFO_SUCCESS, GET_COMPANY_INFO_FAILURE, EDIT_COMPANY_INFO_REQUEST, EDIT_COMPANY_INFO_SUCCESS, EDIT_COMPANY_INFO_FAILURE, FETCH_BRANDS_BY_COMPANY_REQUEST, FETCH_BRANDS_BY_COMPANY_SUCCESS, FETCH_BRANDS_BY_COMPANY_FAILURE } from './actionTypes';

export const getCompanyInfoRequest = () => ({
  type: GET_COMPANY_INFO_REQUEST,
});

export const getCompanyInfoSuccess = (data) => ({
  type: GET_COMPANY_INFO_SUCCESS,
  payload: data,
});

export const getCompanyInfoFailure = (error) => ({
  type: GET_COMPANY_INFO_FAILURE,
  payload: error,
});


export const editCompanyInfoRequest = () => ({
  type: EDIT_COMPANY_INFO_REQUEST,
});

export const editCompanyInfoSuccess = (updatedData) => ({
  type: EDIT_COMPANY_INFO_SUCCESS,
  payload: updatedData, 
});


export const editCompanyInfoFailure = (error) => ({
  type: EDIT_COMPANY_INFO_FAILURE,
  payload: error,
});


export const fetchBrandsByCompanyRequest = () => ({
  type: FETCH_BRANDS_BY_COMPANY_REQUEST,
});

export const fetchBrandsByCompanySuccess = (data) => ({
  type: FETCH_BRANDS_BY_COMPANY_SUCCESS,
  payload: data,
});

export const fetchBrandsByCompanyFailure = (error) => ({
  type: FETCH_BRANDS_BY_COMPANY_FAILURE,
  payload: error,
});
