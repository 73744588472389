import {
    FETCH_ALL_RELEASES_REQUEST,
    FETCH_ALL_RELEASES_SUCCESS,
    FETCH_ALL_RELEASES_FAILURE,
    FETCH_OTHER_RELEASES_REQUEST,
    FETCH_OTHER_RELEASES_SUCCESS,
    FETCH_OTHER_RELEASES_FAILURE,
    FETCH_EDIT_RELEASES_REQUEST,
    POST_RELEASE_REQUEST,
    POST_RELEASE_SUCCESS,
    POST_RELEASE_FAILURE,
    FETCH_RELEASE_BY_ID_REQUEST,
    FETCH_RELEASE_BY_ID_SUCCESS,
    FETCH_RELEASE_BY_ID_FAILURE,
    FETCH_RESET_RELEASE_BY_ID_REQUEST,
  } from './actionTypes';
  
  export const fetchAllReleasesRequest = () => ({
    type: FETCH_ALL_RELEASES_REQUEST,
  });
  
  export const fetchAllReleasesSuccess = (data) => ({
    type: FETCH_ALL_RELEASES_SUCCESS,
    payload: data,
  });
  
  export const fetchAllReleasesFailure = (error) => ({
    type: FETCH_ALL_RELEASES_FAILURE,
    payload: error,
  });
  

export const fetchOtherReleasesRequest = () => ({
  type: FETCH_OTHER_RELEASES_REQUEST,
});

export const fetchOtherReleasesSuccess = (data) => ({
  type: FETCH_OTHER_RELEASES_SUCCESS,
  payload: data,
});

export const fetchOtherReleasesFailure = (error) => ({
  type: FETCH_OTHER_RELEASES_FAILURE,
  payload: error,
});



export const postReleaseRequest = () => ({
  type: POST_RELEASE_REQUEST,
});

export const postReleaseSuccess = (data) => ({
  type: POST_RELEASE_SUCCESS,
  payload: data,
});

export const postReleaseFailure = (error) => ({
  type: POST_RELEASE_FAILURE,
  payload: error,
});

export const fetchReleaseByIdRequest = (id) => ({
  type: FETCH_RELEASE_BY_ID_REQUEST,
  payload: id,
});

export const fetchReleaseByIdSuccess = (data) => ({
  type: FETCH_RELEASE_BY_ID_SUCCESS,
  payload: data,
});

export const fetchReleaseByIdFailure = (error) => ({
  type: FETCH_RELEASE_BY_ID_FAILURE,
  payload: error,
});


export const resetReleaseById = () => ({
  type: FETCH_RESET_RELEASE_BY_ID_REQUEST,
});

