import {
    FETCH_B2C_BRAND_REQUEST,
    FETCH_B2C_BRAND_SUCCESS,
    FETCH_B2C_BRAND_FAILURE,
    FETCH_B2C_DISTILLERY_REQUEST,
    FETCH_B2C_DISTILLERY_SUCCESS,
    FETCH_B2C_DISTILLERY_FAILURE,
    FETCH_B2C_RELEASE_REQUEST,
    FETCH_B2C_RELEASE_SUCCESS,
    FETCH_B2C_RELEASE_FAILURE,
    FETCH_B2C_BRAND_BY_ID_REQUEST,
    FETCH_B2C_BRAND_BY_ID_SUCCESS,
    FETCH_B2C_BRAND_BY_ID_FAILURE,
    FETCH_B2C_COMPANY_LANDINGPAGE_FAILURE,
    FETCH_B2C_COMPANY_LANDINGPAGE_SUCCESS,
    FETCH_B2C_COMPANY_LANDINGPAGE_REQUEST,
  } from './actionTypes';
  
  // Action creators for fetching B2C brands
  export const fetchB2CBrandRequest = () => ({
    type: FETCH_B2C_BRAND_REQUEST,
  });
  
  export const fetchB2CBrandSuccess = (data) => ({
    type: FETCH_B2C_BRAND_SUCCESS,
    payload: data,
  });
  
  export const fetchB2CBrandFailure = (error) => ({
    type: FETCH_B2C_BRAND_FAILURE,
    payload: error,
  });
  
  // Action creators for fetching B2C distilleries
  export const fetchB2CDistilleryRequest = () => ({
    type: FETCH_B2C_DISTILLERY_REQUEST,
  });
  
  export const fetchB2CDistillerySuccess = (data) => ({
    type: FETCH_B2C_DISTILLERY_SUCCESS,
    payload: data,
  });
  
  export const fetchB2CDistilleryFailure = (error) => ({
    type: FETCH_B2C_DISTILLERY_FAILURE,
    payload: error,
  });
  
  // Action creators for fetching B2C releases
  export const fetchB2CReleaseRequest = () => ({
    type: FETCH_B2C_RELEASE_REQUEST,
  });
  
  export const fetchB2CReleaseSuccess = (data) => ({
    type: FETCH_B2C_RELEASE_SUCCESS,
    payload: data,
  });
  
  export const fetchB2CReleaseFailure = (error) => ({
    type: FETCH_B2C_RELEASE_FAILURE,
    payload: error,
  });
  

  export const fetchB2CBrandByIdRequest = () => ({
    type: FETCH_B2C_BRAND_BY_ID_REQUEST,
  });
  
  export const fetchB2CBrandByIdSuccess = (data) => ({
    type: FETCH_B2C_BRAND_BY_ID_SUCCESS,
    payload: data,
  });
  
  export const fetchB2CBrandByIdFailure = (error) => ({
    type: FETCH_B2C_BRAND_BY_ID_FAILURE,
    payload: error,
  });
  

  export const fetchB2CCompanyDetailsRequest = () => ({
    type: FETCH_B2C_COMPANY_LANDINGPAGE_REQUEST,
  });
  
  export const fetchB2CCompanyDetailsSuccess = (data) => ({
    type: FETCH_B2C_COMPANY_LANDINGPAGE_SUCCESS,
    payload: data,
  });
  
  export const fetchB2CCompanyDetailsFailure = (error) => ({
    type: FETCH_B2C_COMPANY_LANDINGPAGE_FAILURE,
    payload: error,
  });
  