import { FETCH_ALL_RELEASES_FAILURE, FETCH_ALL_RELEASES_REQUEST, FETCH_ALL_RELEASES_SUCCESS, FETCH_OTHER_RELEASES_FAILURE, FETCH_OTHER_RELEASES_REQUEST, FETCH_OTHER_RELEASES_SUCCESS, FETCH_RELEASE_BY_ID_FAILURE, FETCH_RELEASE_BY_ID_REQUEST, FETCH_RELEASE_BY_ID_SUCCESS, FETCH_RESET_RELEASE_BY_ID_REQUEST, POST_RELEASE_FAILURE, POST_RELEASE_REQUEST, POST_RELEASE_SUCCESS } from "../actions/Release";

const initialReleasesState = {
  loading: false,
  releases: [],
  error: null,
  otherReleases: [],
  otherReleasesLoading: false,
  otherReleasesError: null,
  releasePostLoading: false,
  releasePostSuccess: false,
  releasePostError: null,
  postReleaseData: {},
  releaseByIdLoading: false,
  releaseById: null,
  releaseByIdError: null,
};

const releasesReducer = (state = initialReleasesState, action) => {
  switch (action.type) {
    case FETCH_ALL_RELEASES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ALL_RELEASES_SUCCESS:
      return {
        ...state,
        loading: false,
        releases: action.payload,
      };
    case FETCH_ALL_RELEASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_OTHER_RELEASES_REQUEST:
      return {
        ...state,
        otherReleasesLoading: true,
        otherReleasesError: null,
      };
    case FETCH_OTHER_RELEASES_SUCCESS:
      return {
        ...state,
        otherReleasesLoading: false,
        otherReleases: action.payload,
        otherReleasesError: null,
      };
    case FETCH_OTHER_RELEASES_FAILURE:
      return {
        ...state,
        otherReleasesLoading: false,
        otherReleasesError: action.payload,
      };
    case POST_RELEASE_REQUEST:
      return {
        ...state,
        releasePostLoading: true,
        releasePostSuccess: false,
        releasePostError: null
      };
    case POST_RELEASE_SUCCESS:
      return {
        ...state,
        releasePostLoading: false,
        releasePostSuccess: true,
        releasePostError: null,
        postReleaseData: action.payload
      };
    case POST_RELEASE_FAILURE:
      return {
        ...state,
        releasePostLoading: false,
        releasePostSuccess: false,
        releasePostError: action.payload
      };
    case FETCH_RELEASE_BY_ID_REQUEST:
      return {
        ...state,
        releaseByIdLoading: true,
        releaseByIdError: null,
      };
    case FETCH_RELEASE_BY_ID_SUCCESS:
      return {
        ...state,
        releaseByIdLoading: false,
        releaseById: action.payload,
        releaseByIdError: null,
      };
    case FETCH_RELEASE_BY_ID_FAILURE:
      return {
        ...state,
        releaseByIdLoading: false,
        releaseByIdError: action.payload,
      };
      case FETCH_RESET_RELEASE_BY_ID_REQUEST:
        return {
          ...state,
          releaseById:null
        };
    default:
      return state;
  }
};

export default releasesReducer;
