import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainHeader from '../components/SubHeader/MainHeader';
import { useLocation } from 'react-router-dom';
const PrivateLayout = ({ children, subHeader = [], headerConfig }) => {
  const location = useLocation();
  const listToHideFooter = ['add-brand', 'add-distillery','add-release','edit-release','view-release'];
  const listToHideMainHeader = ['/add-brand', '/add-distillery', '/distillery/add-brand'];
  const listToHideHeader = [];
  const check = (type) => {
    console.log(location.pathname);
    switch (type) {
      case 'FOOTER': return !listToHideFooter.some((e)=>e==location.pathname?.split('/')?.[1]);
      case 'MAIN_HEADER': return !listToHideMainHeader.some((e)=>e==location.pathname);
      case 'HEADER': return !listToHideHeader.some((e)=>e==location.pathname);
    }

  }
  return (
    <>
    {check('HEADER') && <Header />}
    <MainHeader headerConfig={headerConfig} subHeader={subHeader} />
    {children}
    {check('FOOTER') && <Footer />}
  </>
  )
};

export default PrivateLayout;
