export const FETCH_ALL_RELEASES_REQUEST = 'FETCH_ALL_RELEASES_REQUEST';
export const FETCH_ALL_RELEASES_SUCCESS = 'FETCH_ALL_RELEASES_SUCCESS';
export const FETCH_ALL_RELEASES_FAILURE = 'FETCH_ALL_RELEASES_FAILURE';

export const FETCH_OTHER_RELEASES_REQUEST = 'FETCH_OTHER_RELEASES_REQUEST';
export const FETCH_OTHER_RELEASES_SUCCESS = 'FETCH_OTHER_RELEASES_SUCCESS';
export const FETCH_OTHER_RELEASES_FAILURE = 'FETCH_OTHER_RELEASES_FAILURE';

export const FETCH_EDIT_RELEASES_REQUEST = 'FETCH_EDIT_RELEASES_REQUEST';
export const FETCH_EDIT_RELEASES_SUCCESS = 'FETCH_EDIT_RELEASES_SUCCESS';
export const FETCH_EDIT_RELEASES_FAILURE = 'FETCH_EDIT_RELEASES_FAILURE';

export const POST_RELEASE_REQUEST = 'POST_RELEASE_REQUEST';
export const POST_RELEASE_SUCCESS = 'POST_RELEASE_SUCCESS';
export const POST_RELEASE_FAILURE = 'POST_RELEASE_FAILURE';

export const FETCH_RELEASE_BY_ID_REQUEST = 'FETCH_RELEASE_BY_ID_REQUEST';
export const FETCH_RELEASE_BY_ID_SUCCESS = 'FETCH_RELEASE_BY_ID_SUCCESS';
export const FETCH_RELEASE_BY_ID_FAILURE = 'FETCH_RELEASE_BY_ID_FAILURE';

export const FETCH_RESET_RELEASE_BY_ID_REQUEST = 'FETCH_RESET_RELEASE_BY_ID_REQUEST';
