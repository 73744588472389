import React from 'react'
import "./Flavour_header.css"
const Flavour_header = ({setTab}) => {

    return (
      <nav className="navbar flavour-nav-bar p-1">
        <ul className="navbar-nav flavour-nav d-flex flex-row">
          <li className="nav-item">
            <a className="nav-link nav-item-link" style={{ color: 'grey' }} 
            // onClick={() => setTab('brands')}
            >FLAVOURS</a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link nav-item-link" style={{ color: 'grey' }} onClick={() => setTab('release')}>AROMAS</a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-item-link" style={{ color: 'grey' }} onClick={() => setTab('aftertaste')}>AFTERTASTE</a>
          </li> */}
        </ul>
        <div className="bottom-border"></div>
      </nav>
    );
  };
  

  

export default Flavour_header;