import {
  getCompanyInfoRequest,
  getCompanyInfoSuccess,
  getCompanyInfoFailure,
  editCompanyInfoSuccess,
  editCompanyInfoFailure,
  editCompanyInfoRequest,
  fetchBrandsByCompanyRequest,
  fetchBrandsByCompanySuccess,
  fetchBrandsByCompanyFailure,
} from "./actions";
import {
  BRANDS_BY_COMPANY_ENDPOINT,
  COMPANY_INFO_ENDPOINT,
  EDIT_COMPANY_INFO_ENDPOINT,
  getBrandsByCompanyUrl,
  getEditCompanyInfoUrl,
} from "../../../services/urlHelper";
import { get, put, putFormData } from "../../../services/apiService";
import SuccessModel from "../../../components/SuccessModel";
import { toast } from "react-toastify";

export const getCompanyInfoById = (companyId) => async (dispatch) => {
  dispatch(getCompanyInfoRequest());
  try {
    const response = await get(`${COMPANY_INFO_ENDPOINT}/${companyId}`);
    dispatch(getCompanyInfoSuccess(response.data));
  } catch (error) {
    dispatch(getCompanyInfoFailure(error.message));
  }
};

export const editCompanyInfo = (companyId, newData) => async (dispatch) => {
  dispatch(editCompanyInfoRequest());
  try {
    const response = await putFormData(getEditCompanyInfoUrl(companyId),newData);
    if (response.status === 200) {
      dispatch(editCompanyInfoSuccess(response.data)); // Pass updated data to the success action
      // toast('Updated')
      // <SuccessModel title="Updated" />
    }
  } catch (error) {
    dispatch(editCompanyInfoFailure(error.message));
  }
};

export const fetchBrandsByCompanyId = (companyId) => async (dispatch) => {
  dispatch(fetchBrandsByCompanyRequest());
  try {
    const response = await get(getBrandsByCompanyUrl(companyId));
    dispatch(fetchBrandsByCompanySuccess(response.data));
  } catch (error) {
    dispatch(fetchBrandsByCompanyFailure(error.message));
  }
};
