
export const TODOS_ENDPOINT = '/todos';
export const USERS_ENDPOINT = '/users';
export const LOGIN_ENDPOINT = '/auth/login';
export const COMPANY_INFO_ENDPOINT = '/b2b/company/';
export const ADD_BRAND='b2b/brands';
export const ADD_DISTILLERY='/b2b/distillery';
export const EDIT_COMPANY_INFO_ENDPOINT = '/b2b/company';
export const BRANDS_BY_COMPANY_ENDPOINT = '/b2b/brands';
export const DISTILLERIES_BY_COMPANY_ENDPOINT = '/b2b/distillery';
export const ALL_RELEASES_ENDPOINT = '/b2b/release';
export const OTHER_RELEASES_ENDPOINT = '/b2b/release/other';
export const GET_B2C_COMPANIES_ENDPOINT = '/b2c/brands';
export const B2C_BRANDS_ENDPOINT = '/b2c/brands';
export const B2C_DISTILLERIES_ENDPOINT = '/b2c/distillery';
export const B2C_RELEASES_ENDPOINT = '/b2c/release';
export const IMG_DISPLAY='/uploads/'
export const B2C_BRAND_BY_ID_ENDPOINT = '/b2c/brands';
export const OTHER_DISTILLERY='/b2b/distillery/other';
export const OTHER_BRAND='/b2b/brands/other';
export const POST_RELEASES_ENDPOINT = '/b2b/release';
export const GET_RELEASE_BY_ID_ENDPOINT = '/b2b/release';
export const B2C_COMPANY_DETAILS_ENDPOINT='/b2c/company/details'


export const getOtherBrandsUrl= () => OTHER_BRAND;
// export const getBrandsUrl = (page) => `${ADD_BRAND}?currentPage=${page}&limit=10`;
export const getBrandsUrl = (page) => `${ADD_BRAND}`;
export const getImageUrl = (img) => `${process.env.REACT_APP_API_URL}${IMG_DISPLAY}/${img}`;
export const getTodosUrl = () => TODOS_ENDPOINT;
export const getUserUrl = (userId) => `${USERS_ENDPOINT}/${userId}`;
export const postLoginUrl = () => LOGIN_ENDPOINT;
export const getCompanyInfoUrl = (userId) => `${COMPANY_INFO_ENDPOINT}/${userId}`;
export const addBrandUrl = () => ADD_BRAND;
export const addDistilleryUrl = () => ADD_DISTILLERY;
export const getOtherDistilleryUrl = () => OTHER_DISTILLERY;
export const editDistilleryUrl = (id) =>  `${ADD_DISTILLERY}/${id}`;
export const getEditCompanyInfoUrl = (companyId) => `${EDIT_COMPANY_INFO_ENDPOINT}/${companyId}`;
export const getBrandsByCompanyUrl = (companyId) => `${BRANDS_BY_COMPANY_ENDPOINT}?parentId=${companyId}`;
export const getDistilleriesByCompanyUrl = (companyId) => `${DISTILLERIES_BY_COMPANY_ENDPOINT}?companyId=${companyId}`;
export const getAllReleasesUrl = () => ALL_RELEASES_ENDPOINT;
export const getOtherReleasesUrl = () => OTHER_RELEASES_ENDPOINT;
export const getB2CCompaniesUrl = () => GET_B2C_COMPANIES_ENDPOINT;
export const getB2CBrandsUrl = () => B2C_BRANDS_ENDPOINT;
export const getB2CDistilleriesUrl = () => B2C_DISTILLERIES_ENDPOINT;
export const getB2CReleasesUrl = () => B2C_RELEASES_ENDPOINT;
export const getB2CBrandByIdUrl = (brandId) => `${B2C_BRAND_BY_ID_ENDPOINT}/${brandId}`;
export const getB2CCompanyUrl= () => B2C_COMPANY_DETAILS_ENDPOINT;
export const postReleaseUrl = () => POST_RELEASES_ENDPOINT;
export const getReleaseByIdUrl = (id) => `${GET_RELEASE_BY_ID_ENDPOINT}/${id}`;





