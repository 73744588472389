export const GET_COMPANY_INFO_REQUEST = 'GET_COMPANY_INFO_REQUEST';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const GET_COMPANY_INFO_FAILURE = 'GET_COMPANY_INFO_FAILURE';

export const EDIT_COMPANY_INFO_REQUEST = 'EDIT_COMPANY_INFO_REQUEST';
export const EDIT_COMPANY_INFO_SUCCESS = 'EDIT_COMPANY_INFO_SUCCESS';
export const EDIT_COMPANY_INFO_FAILURE = 'EDIT_COMPANY_INFO_FAILURE';

export const FETCH_BRANDS_BY_COMPANY_REQUEST = 'FETCH_BRANDS_BY_COMPANY_REQUEST';
export const FETCH_BRANDS_BY_COMPANY_SUCCESS = 'FETCH_BRANDS_BY_COMPANY_SUCCESS';
export const FETCH_BRANDS_BY_COMPANY_FAILURE = 'FETCH_BRANDS_BY_COMPANY_FAILURE';
