import React from 'react'
import "./MainHeader.css"
import { Link, useLocation } from 'react-router-dom'
const MainHeader = ({ subHeader = {}, headerConfig = [] }) => {
  const location = useLocation()

  return (
    <>
      <nav  className="navbar third-nav-bar navbar-dark Nav_Header_Main">
        <div className="container justify-content-center">
          <ul className="navbar-nav third-nav d-flex flex-row gap-3">
            {headerConfig.map((item, index) => (
              <li className="nav-item" key={index}>
                <Link className={`nav-link n-1 ${item.link === location.pathname || (location.pathname.includes('view-release') && item.name === "Releases") ? 'active' : ''}`} to={item.link}>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="bottom-border"></div>
      </nav>
      {subHeader?.subHeader?.length > 0 ? <nav className="navbar navbar-dark new-header pb-0">
        <div style={{width:'1130px'}} className="container px-xl-5">
          <ul className="navbar-nav  third-nav-all d-flex flex-row flex-nowrap gap-4 py-xl-3 py-lg-3 py-md-3 py-0">
            {subHeader?.subHeader.map((item, index) => (
              <li className={`nav-item`} key={index}>
                <Link className={`nav-link n-2  ${item.link === location.pathname ? 'active' : ''}`} to={item.link}>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          {/* <div className="ml-auto d-flex gap-3 third-nav-btn">
            <button className="btn btn-outline-light mr-2">Import Catalog Data</button>
            <button className="btn btn-light">New Release</button>
          </div> */}
          {subHeader?.subHeaderButtons?.length > 0 ? (
            <div className="ml-auto d-flex gap-3 third-nav-btn">
              {subHeader.subHeaderButtons.map((item, index) => (
                index === 0 ? (
                  <button key={index} className="ct_outline_btn" style={{ width: '175px' }}>
                    {item?.name}
                  </button>
                ) : index === 1 ? (
                  <button key={index} className="ct_custom_bg_btn text-white" style={{ width: '130px', opacity: '1' }}>
                    <Link className="text-white" to={item?.link}>{item?.name}</Link>
                  </button>
                ) : (
                  ''
                )
              ))}
            </div>
          ) : null}

          <div className="bottom-border1"></div>
        </div>
      </nav> : ''}
    </>
  )
}

export default MainHeader