import { FETCH_BRAND_REQUEST, FETCH_BRAND_SUCCESS, FETCH_BRAND_FAILURE, FETCH_OTHER_BRAND_FAILURE, FETCH_OTHER_BRAND_SUCCESS, FETCH_OTHER_BRAND_REQUEST, FETCH_EDIT_BRAND_REQUEST, FETCH_EDIT_BRAND_SUCCESS, FETCH_EDIT_BRAND_FAILURE, FETCH_ADD_BRAND_REQUEST, FETCH_ADD_BRAND_SUCCESS, FETCH_ADD_BRAND_FAILURE } from './actionTypes';

export const fetchBrandRequest = () => ({
  type: FETCH_BRAND_REQUEST,
});

export const fetchBrandSuccess = (data) => ({
  type: FETCH_BRAND_SUCCESS,
  payload: data,
});

export const fetchBrandFailure = (error) => ({
  type: FETCH_BRAND_FAILURE,
  payload: error,
});


export const fetchOtherBrandRequest = () => ({
  type: FETCH_OTHER_BRAND_REQUEST,
});

export const fetchOtherBrandSuccess = (data) => ({
  type: FETCH_OTHER_BRAND_SUCCESS,
  payload: data,
});

export const fetchOtherBrandFailure = (error) => ({
  type: FETCH_OTHER_BRAND_FAILURE,
  payload: error,
});

export const fetchEditBrandRequest = () => ({
  type: FETCH_EDIT_BRAND_REQUEST,
});

export const fetchEditBrandSuccess = (data) => ({
  type: FETCH_EDIT_BRAND_SUCCESS,
  payload: data,
});

export const fetchEditBrandFailure = (error) => ({
  type: FETCH_EDIT_BRAND_FAILURE,
  payload: error,
});


export const fetchAddBrandRequest = () => ({
  type: FETCH_ADD_BRAND_REQUEST,
});

export const fetchAddBrandSuccess = (data) => ({
  type: FETCH_ADD_BRAND_SUCCESS,
  payload: data,
});

export const fetchAddBrandFailure = (error) => ({
  type: FETCH_ADD_BRAND_FAILURE,
  payload: error,
});