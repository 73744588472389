import React from 'react';
import './BrandIntro.css';
import brandLOGO from '../../../../assets/img/brandLOGO.png'
import brandslide from '../../../../assets/img/brandslide.png'
import BacheGabrielsen from '../../../../assets/img/BacheGabrielsen.png'
import sideArrow from '../../../../assets/img/sideArrow.png'
const BrandIntro = () => {
    return (
        <>
    
        <div className='d-flex flex-column align-items-center justify-content-center brandIntro mt-5 '>
            <div className='p-4'>
                <p className='Brand_head'>Brand introduction</p>

                <div className='d-flex gap-2 align-items-center justify-content-center brands_responsive_imgs'>
                    <img src={brandLOGO} alt="" className="" />
                    <img src={BacheGabrielsen} alt="" className="ms-2" />

                </div>
                <div className='mt-3'>
                    <p className="brand_text" >ABOUT</p>
                    <p className="brand_font_16" >Found in 1911, lorem ipsum this cognac has a very pleasant golden tint and the aromatics show classic scents of baked, white grapes, hard lemon candy, and opulent French oak. </p>
                    <p className="brand_text" >TRENDING PRODUCTS</p>
                </div>
            </div>



            <div className=''>
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={brandslide} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={brandslide} className="d-block w-100" alt="..."/>
                        </div>
                        <div className="carousel-item">
                            <img src={brandslide} className="d-block w-100" alt="..."/>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon slider_icon_prev" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon slider_icon_next" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

            </div>

            <div className='trending_text bg-black d-block w-100 pt-3'>
                <p className=''>Brand’s trending product name</p>
            </div>
            <div className='trending_bt d-block w-100 text-center'>
                <p className='Brand_head mb-0'>Explore the brand <span className='ms-2'><img src={sideArrow} alt='sideArrow' className=""/></span></p>
            </div>

        </div>

</>
    )
}
export default BrandIntro;