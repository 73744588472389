import { fetchBrandRequest, fetchBrandSuccess, fetchBrandFailure, fetchOtherBrandFailure, fetchOtherBrandSuccess, fetchOtherBrandRequest, fetchEditBrandRequest, fetchEditBrandSuccess, fetchEditBrandFailure, fetchAddBrandRequest, fetchAddBrandSuccess, fetchAddBrandFailure } from './actions';

import { ADD_BRAND, addBrandUrl, getBrandsUrl, getOtherBrandsUrl } from '../../../services/urlHelper';
import { get, post, put } from '../../../services/apiService';

export const addBrand = (data) => async (dispatch) => {
  dispatch(fetchAddBrandRequest());
  try {
    const response = await post(addBrandUrl(),data);
    dispatch(fetchAddBrandSuccess(response.data));
  } catch (error) {
    dispatch(fetchAddBrandFailure(error.message));
  }
};

export const editBrand = (id,data) => async (dispatch) => {
  dispatch(fetchEditBrandRequest());
  try {
    const response = await put(`${ADD_BRAND}/${id}`,data);
    dispatch(fetchEditBrandSuccess(response.data));
  } catch (error) {
    dispatch(fetchEditBrandFailure(error.message));

  }
};

export const fetchBrandsList = (page) => async (dispatch) => {
  dispatch(fetchBrandRequest());
  try {
    const response = await get(getBrandsUrl(page));
    dispatch(fetchBrandSuccess(response.data));
  } catch (error) {
    dispatch(fetchBrandFailure(error.message));
  }
  
};
  export const fetchOtherBrandsList = () => async (dispatch) => {
    dispatch(fetchOtherBrandRequest());
    try {
      const response = await get(getOtherBrandsUrl());
      dispatch(fetchOtherBrandSuccess(response.data));
    } catch (error) {
      dispatch(fetchOtherBrandFailure(error.message));
    }
};