import React, { useEffect, useState } from "react";
import "./AddRelease.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchTodos } from "../../store/actions/todos";
import FormSubFooter from "../../components/SubFooter/FormSubFooter";
import FileUpload from "../../components/FileUpload";
import { addBrand } from "../../store/actions/Brand";
import plusImg from "../../assets/images/plus4x.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { fetchBrandsByCompanyId } from "../../store/actions/company";
import RadarChartMain from "./RadarChartMain";
import upload_img_white from "../../assets/images/uploadBtnBrownBg.png";
import { Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { getImageUrl } from "../../services/urlHelper";
import Modal from 'react-bootstrap/Modal';
import SuccessModel from "../../components/SuccessModel";
import { editRelease, fetchReleaseById } from "../../store/actions/Release";
import Loader from "../../components/Loader/Loader";
const EditRelease = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const { loading, releases, error,releaseByIdLoading } = useSelector((state) => state.releases);
  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); navigate('/release') };
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const [releaseById, setreleaseById] = useState([])
  // const { releaseById } = useSelector((state) => state.releases);
  const { brands } = useSelector((state) => state.company);
  const location = useLocation();
  const { releaseDate } = location.state || {};

  useEffect(() => {
    if (id) {
      const filterRelease = releases.data?.filter((item)=>item._id === id)
      console.log(filterRelease[0],"releaseById");
      setreleaseById(filterRelease[0])
      // dispatch(fetchReleaseById(id))
    }
  }, [id])



  const [currentTab, setCurrentTab] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [logo, setLogo] = useState(null);
  const [coverImg, seCoverImg] = useState(null);
  const [galleryImg, setGalleryImg] = useState([]);
  const [previewLogo, setPreviewLogo] = useState(null);
  const [previewCoverImg, setPreviewCoverImg] = useState(null);
  const [previewGalleryImg, setPreviewGalleryImg] = useState([]);
  //   const dateString = '2024-05-27T00:00:00.000Z';

  // Convert to Date object
  //   const date = new Date(dateString);
  const cancel = () => {
    console.log("Canceled");
    navigate('/release')
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };
  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };
  const saveAsDraft = () => {
    console.log("saveAsDraft");
  };
  const handleRatings = (item, value) => {
    item.rating = value;
    // let item
    // let list=[...formik.values.selectedOptions,...item];
    // list = list?.map((e)=>{
    //     if(e.label==item.value){
    //         e.rating=value
    //     }
    //     return e;
    // })
    // console.log(list);
    // formik.setFieldValue('selectedOptions', list);
  }
  const validationSchemaPage1 = Yup.object({
    brandId: Yup.string().required('Brand ID is required'),
    name: Yup.string().required('Name is required'),
    ean: Yup.number()
      .required('ean is required'),
    description: Yup.string().required('Description is required'),
    location: Yup.string().required('Location is required'),
    region: Yup.string().required('Region is required'),
    price: Yup.number()
    .required('Price is required'),  
    releaseDate: Yup.date().required('Release Date is required'),
    abv: Yup.number()
      .required('ABV is required')
      .min(0, 'ABV must be a positive number')
      .max(100, 'ABV must be less than or equal to 100'),
    type: Yup.string().required('Type is required'),
  });

  const validationSchemaPage2 = Yup.object({
    // logo: Yup.mixed().required('Logo is required'),
    coverImage: Yup.mixed().required('Cover Image is required'),
    images: Yup.mixed().required('Images are required'),
  });

  const validationSchemaPage3 = Yup.object({
    color: Yup.string().required('Color Description is required'),
    nose: Yup.string().required('Fragrance Description is required'),
    finish: Yup.string().required('Finish Notes Description is required'),
    palate: Yup.string().required('Palate Description is required'),
  });
  const imgSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  const formik = useFormik({
    initialValues: {
      brandId: releaseById?.brandId ?  releaseById?.brandId : "",
      name: releaseById?.name ? releaseById?.name: "",
      ean: releaseById?.ean  ? releaseById?.ean  :"",
      description: releaseById?.description || "",
      location: releaseById?.location || "",
      region: releaseById?.region || "",
      price: releaseById?.price || "",
      releaseDate: releaseById?.releaseDate ? new Date(releaseById?.releaseDate)?.toISOString().split('T')[0] : "",
      abv: releaseById?.abv || "",
      type: releaseById?.type || "",
      color: releaseById?.color || "",
      finish: releaseById?.finish || "",
      nose: releaseById?.nose || "",
      palate: releaseById?.palate || "",
      selectedOptions: releaseById?.selectedOptions || [],
      logo: releaseById?.logo || "",
      coverImage:releaseById?.coverImage || "",
      images:releaseById?.images || "",
      afterTaste: []
    }, validationSchema: currentPage === 1
      ? validationSchemaPage1
      : currentPage === 2
        ? validationSchemaPage2
        : currentPage === 3
          ? validationSchemaPage3
          : null,
    onSubmit: (values) => {
      const { logo,brandId, name, releaseDate, description, location, price, region, abv, color, ean, type, coverImage, images, nose, finish, palate } = values
      const formData = new FormData();
      formData.append("brandId", brandId);
      formData.append("name", name);
      formData.append("releaseDate", releaseDate);
      formData.append("description", description);
      formData.append("location", location);
      formData.append("price", price);
      formData.append("region", region);
      formData.append("abv", abv);
      formData.append("color", color);
      formData.append("ean", ean);
      formData.append("type", type);
      formData.append("nose", nose);
      formData.append("finish", finish);
      formData.append("palate", palate);
      formData.append("logo", logo);

      // Append coverImage and images
      formData.append("coverImage", coverImage);
      images.forEach((image, index) => {
        formData.append(`images`, image);
      });
        dispatch(editRelease(releaseById._id,formData)).then((data) => {
          if (!loading) {
              setShow(true);

              setTimeout(()=>{
                  setShow(false);
                  navigate('/release');
              },2500)
          } else {
              setShow(false);
          }
      })
          .catch((error) => {
          });
    },
  });

  useEffect(() => {
    if (releaseById) {
      formik.setFieldValue('brandId', releaseById.brandId);
      formik.setFieldValue('name', releaseById.name);
      formik.setFieldValue('releaseDate',  releaseById?.releaseDate ? new Date(releaseById?.releaseDate)?.toISOString().split('T')[0] : "");
      formik.setFieldValue('description', releaseById.description);
      formik.setFieldValue('location', releaseById.location);
      formik.setFieldValue('price', releaseById.price);
      formik.setFieldValue('region', releaseById.region);
      formik.setFieldValue('abv', releaseById.abv);
      formik.setFieldValue('color', releaseById.color);
      formik.setFieldValue('ean', releaseById.ean);
      formik.setFieldValue('type', releaseById.type);
      formik.setFieldValue('nose', releaseById.nose);
      formik.setFieldValue('finish', releaseById.finish);
      formik.setFieldValue('palate', releaseById.palate);
      formik.setFieldValue('coverImage', releaseById?.coverImage)
      formik.setFieldValue('logo', releaseById?.logo)
      formik.setFieldValue('images', releaseById?.images)
    }
  }, [releaseById]);

  useEffect(() => {
    if (releaseById) {
      // setLogo(releaseById?.logo);
      // setPreviewCoverImg(releaseById?.coverImage);
      // setGalleryImg(releaseById?.images);
      // formik.setFieldValue('logo', releaseById?.logo)
      // formik.setFieldValue('coverImage', releaseById?.coverImage)
      // formik.setFieldValue('images', releaseById?.images)
      setPreviewLogo(getImageUrl(releaseById?.logo));
      setPreviewCoverImg(getImageUrl(releaseById?.coverImage));
      let imgaesList = releaseById?.images?.map((e) => getImageUrl(e));
      setPreviewGalleryImg(imgaesList);
    }
  }, [releaseById]);
  const labelsArray = formik.values.selectedOptions.length > 0 ? formik.values.selectedOptions.map(option => option.label) : [];
  const valuesArray = formik.values.selectedOptions.length > 0 ? formik.values.selectedOptions.map(option => option.value) : [];

  const handleRemove = (option) => {
    formik.setFieldValue('selectedOptions', formik.values.selectedOptions.filter((item) => item.value !== option.value));
  };
  // const proceed = () => {
  //   if (currentPage < 3) {
  //     console.log(currentPage, "currentPage");
  //     setCurrentPage(currentPage + 1);
  //     console.log("Proceed");
  //     console.log(formik.handleSubmit());
  //   }
  // };
  const proceed = async () => {
    const errors = await formik.validateForm();
    console.log(errors,"currentPage");
    if (currentPage === 1 && Object.keys(errors).length === 0) {
      setCurrentPage(2);
    } else if (currentPage === 2 && Object.keys(errors).length === 0) {
      setCurrentPage(3);
    } else {
      formik.handleSubmit()
    }

  };

  const navigatePageTo = (pageNo) => {
    setCurrentPage(pageNo);
  };
  const handleUpload = (imgType, file) => {
    let imagReader;
    const reader = new FileReader();

    switch (imgType) {
      case "LOGO": {
        // setBrandLogo(file)
        formik.setFieldValue("logo", file);
        reader.onloadend = () => {
          setPreviewLogo(reader.result);
        };
        reader.readAsDataURL(file);
        break;
      }
      case "COVER_IMG": {
        // setBrandCoverImg(file)
        formik.setFieldValue("coverImage", file);
        reader.onloadend = () => {
          setPreviewCoverImg(reader.result);
        };
        reader.readAsDataURL(file);
        break;
      }
      case "GALLERY_IMG": {
        // setGalleryImg(file)
        formik.setFieldValue("images", file);
        reader.onloadend = () => {
          setPreviewGalleryImg(reader.result);
        };
        reader.readAsDataURL(file);
        break;
      }
    }
  };

  const options = [

    { "label": "Vanilla", "value": "Vanilla" },
    { "label": "Caramel", "value": "Caramel" },
    { "label": "Honey", "value": "Honey" },
    { "label": "Fruity", "value": "Fruity" },
    { "label": "Spicy", "value": "Spicy" },
    { "label": "Nutty", "value": "Nutty" },
    { "label": "Malty", "value": "Malty" },
    { "label": "Smoky", "value": "Smoky" },
    { "label": "Peaty", "value": "Peaty" },
    { "label": "Floral", "value": "Floral" },
    { "label": "Butterscotch", "value": "Butterscotch" },
    { "label": "Chocolate", "value": "Chocolate" },
    { "label": "Coffee", "value": "Coffee" },
    { "label": "Toffee", "value": "Toffee" },
    { "label": "Oaky", "value": "Oaky" },
    { "label": "Herbal", "value": "Herbal" },
    { "label": "Citrus", "value": "Citrus" },
    { "label": "Leather", "value": "Leather" },
    { "label": "Tobacco", "value": "Tobacco" },
    { "label": "Sherry", "value": "Sherry" },
    { "label": "Wine", "value": "Wine" },
    { "label": "Anise", "value": "Anise" },
    { "label": "Ginger", "value": "Ginger" },
    { "label": "Coconut", "value": "Coconut" },
    { "label": "Bacon", "value": "Bacon" },
    { "label": "Almond", "value": "Almond" },
    { "label": "Green Tea", "value": "Green Tea" },
    { "label": "Maple Syrup", "value": "Maple Syrup" },
    { "label": "Eucalyptus", "value": "Eucalyptus" },
    { "label": "Marzipan", "value": "Marzipan" }


  ];
  //   const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(option => ({
      label: option.label,
      value: option.value,
      rating: '-'
    }));

    // Merge the old and new selected options
    const updatedSelectedOptions = [...formik.values.selectedOptions, ...selectedOptions];

    // Remove duplicates from the merged array (optional)
    const uniqueSelectedOptions = Array.from(new Set(updatedSelectedOptions.map(option => option.value)))
      .map(value => updatedSelectedOptions.find(option => option.value === value));

    formik.setFieldValue('selectedOptions', uniqueSelectedOptions);
  };


  return (
    <>
    {
      !loading 
      ?
      <>
     
      <section className="ct_mt_65 mt-4" style={{ marginBottom: "5rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-9 mx-auto">
              {currentPage == 1 && (
                <div className="ct_dark_grey_form_bg pb-0">
                  <div className="ct_form_head">
                    <div className="ct_head_left">
                      <h4 className="ct_Cormorant_font ct_fs_24 text-white mb-0">
                        EDIT RELEASE
                      </h4>
                    </div>
                    <div className="ct_head_right">
                      <h5 className="ct_fs_24 ct_Cormorant_font text-white">
                        Step 1 of 3
                      </h5>
                    </div>
                  </div>
                  <div className="ct_form_data">
                    <form>
                      <h6 className="ct_fs_20 ct_Cormorant_font text-white">
                        Main Release Info
                      </h6>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="">Title</label>
                            <input
                              type="text"
                              placeholder="Add title of release"
                              className="ct_input"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                          </div>
                          {formik.touched.name && formik.errors.name && (
                              <div className="error text-white">
                                {formik.errors.name}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="">EAN</label>
                            <input
                              type="text"
                              name="ean"
                              placeholder="Type EAN"
                              className="ct_input"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.ean}
                            />
                          </div>
                          {formik.touched.ean && formik.errors.ean && (
                              <div className="error text-white">
                                {formik.errors.ean}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="">Type</label>
                            <input
                              type="text"
                              placeholder="Add type"
                              className="ct_input"
                              name="type"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.type}
                            />
                          </div>
                          {formik.touched.type && formik.errors.type && (
                              <div className="error text-white">
                                {formik.errors.type}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="publication-date">
                              Publication Date
                            </label>
                            <input
                              type="date"
                              name="releaseDate"
                              placeholder="When release will be published"
                              id="publication-date"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.releaseDate}
                              className="ct_input"
                            />
                          </div>
                          {formik.touched.releaseDate && formik.errors.releaseDate && (
                              <div className="error text-white">
                                {formik.errors.releaseDate}
                              </div>
                            )}
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="">Country</label>
                            <input
                              type="text"
                              name="location"
                              onChange={formik.handleChange}
                              placeholder="Country"
                              className="ct_input"
                              onBlur={formik.handleBlur}
                              value={formik.values.location}
                            />
                          </div>
                          {formik.touched.location && formik.errors.location && (
                              <div className="error text-white">
                                {formik.errors.location}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="">Region</label>
                            <input
                              type="text"
                              name="region"
                              onChange={formik.handleChange}
                              placeholder="Region"
                              className="ct_input"
                              onBlur={formik.handleBlur}
                              value={formik.values.region}
                            />
                          </div>
                          {formik.touched.region && formik.errors.region && (
                              <div className="error text-white">
                                {formik.errors.region}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="">Price band</label>
                            <input
                              type="text"
                              placeholder="Set the  price of this release"
                              className="ct_input"
                              name="price"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.price}
                            />
                          </div>
                          {formik.touched.price && formik.errors.price && (
                              <div className="error text-white">
                                {formik.errors.price}
                              </div>
                            )}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label htmlFor="">ABV</label>
                            <input
                              type="text"
                              placeholder="Add ABV of this release"
                              className="ct_input"
                              name="abv"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.abv}
                            />
                          </div>
                          {formik.touched.abv && formik.errors.abv && (
                              <div className="error text-white">
                                {formik.errors.abv}
                              </div>
                            )}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label htmlFor="">Description</label>
                            <textarea
                              className="ct_input h-auto"
                              rows={4}
                              placeholder="Type here"
                              name="description"
                              defaultValue={""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.description}
                            />
                          </div>
                          {formik.touched.description && formik.errors.description && (
                              <div className="error text-white">
                                {formik.errors.description}
                              </div>
                            )}
                        </div>
                        {/* <div className="col-md-12">
                          <div className="ct_multiple_links pb-4">
                            <div className="position-relative">
                              <input
                                type="text"
                                placeholder="Add Facebook link"
                                className="ct_round_links_input"
                              />
                              <img
                                src={plusImg}
                                style={{ width: "1.4rem" }}
                                className="ct_plus_position_center"
                                alt=""
                              />
                            </div>
                            <div className="position-relative">
                              <input
                                type="text"
                                placeholder="Add Instagram link"
                                className="ct_round_links_input"
                              />
                              <img
                                src={plusImg}
                                style={{ width: "1.4rem" }}
                                className="ct_plus_position_center"
                                alt=""
                              />
                            </div>
                            <div className="position-relative">
                              <input
                                type="text"
                                placeholder="Add X link"
                                className="ct_round_links_input"
                              />
                              <img
                                src={plusImg}
                                style={{ width: "1.4rem" }}
                                className="ct_plus_position_center"
                                alt=""
                              />
                            </div>
                            <div className="position-relative">
                              <input
                                type="text"
                                placeholder="Add {characteristic}"
                                className="ct_round_links_input"
                              />
                              <img
                                src={plusImg}
                                style={{ width: "1.4rem" }}
                                className="ct_plus_position_center"
                                alt=""
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {currentPage == 2 && (
                <section className="ct_mt_65 my-5 mt-4 ">
                  {/* Added mb-4 class for bottom margin */}
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9 mx-auto  w-100">
                        {/* Step form 1 */}
                        <div className="ct_dark_grey_form_bg pb-0" id="step_1">
                          <div className="ct_form_head d-flex justify-content-between align-items-center">
                            <div>
                              <h4 className="ct_Cormorant_font ct_fs_24 text-white mb-0">
                                EDIT RELEASE
                              </h4>
                            </div>
                            <h5 className="ct_fs_24 ct_Cormorant_font text-white">
                              Step 2 of 3
                            </h5>
                            {/* <div>
                              <button className="ct_outline_btn">
                                <Link
                                  to="/release"
                                  className="ct_outline_btn mb-xs-2"
                                  style={{ border: "none" }}
                                >
                                  Cancel
                                </Link>
                              </button>
                              <button
                                className="ct_outline_btn mx-2 mb-xs-2"
                                onClick={() => navigatePageTo(1)}
                              >
                                Back
                              </button>
                              <button className="ct_outline_btn mx-2 mb-xs-2">
                                Save draft
                              </button>
                              <button
                                className="ct_custom_bg_btn"
                                onClick={formik.handleSubmit}
                              >
                                Publish
                              </button>
                            </div> */}
                          </div>
                          <div className="ct_form_data">
                            <form action="#">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-12">
                                    {/* <h5 className="ct_fs_24 ct_Cormorant_font text-white">
                                      Step 2 of 3
                                    </h5> */}
                                    <h6 className="ct_fs_20 ct_Cormorant_font text-white">
                                      Release Media content
                                    </h6>
                                    <p className="ct_fs_10 ct_Cormorant_font text-white">
                                      Please upload all required media files which represent
                                      your release
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="row mt-3">
                                  <div className="col-md-4">
                                   
                                    <div
                                      style={{ border: "2px solid", borderStyle: "dashed" }}
                                      className=" p-3 rounded text-center"

                                    >  <FileUpload img={previewLogo} imageTitle={'Logo'} uploadedImage={(e) => handleUpload('LOGO', e)} />
                                 </div>
                                      <div
                                      style={{ border: "2px solid", borderStyle: "dashed" }}
                                      className=" p-3 rounded text-center"

                                    >
                           <FileUpload img={previewCoverImg} imageTitle={'Cover Image'} uploadedImage={(e) => handleUpload('COVER_IMG', e)} />
                                    </div>
                                  </div>


                                  <div className="col-md-8" style={{
                                    border: "2px solid",
                                    borderStyle: "dashed",
                                  }}>
                                    <div
                                      style={{
                                        height: "100%",
                                      }}
                                      className="   rounded text-center mb-2"
                                    >
                                      {" "}<FileUpload img={previewGalleryImg} imageTitle={'Gallery: Images'} uploadedImage={(e) => handleUpload('GALLERY_IMG', e)} />

                                    </div>
                                  </div>
                                </div> */}
                                   <div className="row mt-3">
                                  <div className="col-md-12">
                                    {/* <label for="">Please upload all required media files which represent your
                                      brand</label> */}
                                    <div className="ct_media_content_upload_main">
                                      {/* className="ct_brand_logo_upload" */}
                                      <div >
                                        <div className='mb-2' style={{ height: '6rem' }}>
                                          <FileUpload
                                            multiple={false}
                                            img={previewLogo}
                                            imageTitle={'Release Logo'}
                                            uploadedImage={(e) => handleUpload('LOGO', e)}
                                          />
                                        </div>
                                        <div style={{ height: '6rem' }}>
                                          <FileUpload
                                            multiple={false}
                                            img={previewCoverImg}
                                            imageTitle={'Release Cover Image'}
                                            uploadedImage={(e) => handleUpload('COVER_IMG', e)}
                                          />
                                        </div>
                                      </div>
                                      <div className="ct_full_img_upload" style={{ overflowX: 'overlay' }}>
                                        <FileUpload
                                          multiple={true}
                                          settings={imgSettings}
                                          img={previewGalleryImg}
                                          imageTitle={'Gallery: Images'}
                                          uploadedImage={(e) => handleUpload('GALLERY_IMG', e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        {/* Step form 1 End */}
                        {/* Step form 2 */}
                        <div
                          className="ct_dark_grey_form_bg d-none pb-0"
                          id="step_2"
                        >
                          <div className="ct_form_head d-flex justify-content-between align-items-center">
                            <div>
                              <h4 className="ct_Cormorant_font ct_fs_24 text-white mb-0">
                                Edit RELEASE
                              </h4>
                            </div>
                            <div>
                              <button className="ct_outline_btn">
                                <Link
                                  to="/release"
                                  className="ct_outline_btn"
                                  style={{ border: "none" }}
                                >
                                  Cancel
                                </Link>
                              </button>
                              <button
                                className="ct_outline_btn"
                                onClick={() => navigatePageTo(1)}
                              >
                                Back
                              </button>
                              {/* <button className="ct_outline_btn">
                                Save Draft
                              </button> */}
                              <button
                                className="ct_custom_bg_btn"
                                onClick={()=>proceed()}
                              >
                                Publish
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* Step form 2 End */}
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {currentPage == 3 && (
                <section className="ct_mt_65 mt-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <div className="ct_dark_grey_form_bg pb-0" id="step_1">
                          <div className="ct_form_head d-flex justify-content-between align-items-center">
                            <h4 className="ct_Cormorant_font ct_fs_24 text-white mb-0">
                              EDIT RELEASE
                            </h4>
                            <h5 className="ct_fs_24 ct_Cormorant_font text-white">
                              Step 3 of 3
                            </h5>
                          </div>


                          <div className="ct_form_head border-0 p-3">
                            <div className="ct_head_right d-flex flex-wrap justify-content-start justify-content-md-start mb-3">
                              <button
                                className={`btn btn-dark ct_Cormorant_font fw-normal fs-5 d-flex align-items-center gap-2 ${currentTab === 1 ? "active" : ""
                                  }`}
                                type="button"
                                onClick={() => handleTabChange(1)}
                              >
                                INITIAL
                              </button>
                              <button
                                className={`btn btn-dark ct_Cormorant_font fw-normal fs-5 d-flex align-items-center gap-2 ${currentTab === 2 ? "active" : ""
                                  }`}
                                type="button"
                                onClick={() => handleTabChange(2)}
                              >
                                <span className="circle-count ct_Cormorant_font fw-normal">1</span>
                                FLAVOURS
                              </button>
                              <button
                                className={`btn btn-dark ct_Cormorant_font fw-normal fs-5 d-flex align-items-center gap-2 ${currentTab === 4 ? "active" : ""
                                  }`}
                                type="button"
                                onClick={() => handleTabChange(4)}
                              >
                                <span className="circle-count ct_Cormorant_font fw-normal">2</span>
                                AFTERTASTE
                              </button>
                            </div>
                          </div>



                          <div
                            className="ct_form_data form-container px-3"
                            style={{ padding: 0 }}
                          >
                            {currentTab === 1 && (
                              <>
                                <div className="w-80 w-xs-80  text-white fw-light mb-0 text-start text-md-start">
                                  Please describe the color, fragrance, Palate
                                  and finish notes of the product to lorem ipsum
                                  dolor sit amet consecteteur elit sed nisi.
                                </div>
                                <div
                                  className="ct_form_data form-container"
                                  style={{ padding: 0 }}
                                >
                                  <form action="#">
                                    <div className="row mt-3">
                                      <div className="col-md-6">
                                        <div className="form-group mb-4 mb-xs-3">
                                          <label htmlFor="colorDescription">
                                            Describe the color
                                          </label>
                                          <textarea
                                            placeholder="NN Characters max"
                                            className="ct_textarea fw-light"
                                            rows="2"
                                            id="colorDescription"
                                            name="color"
                                            defaultValue={""}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.color}
                                          ></textarea>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group mb-4 mb-xs-3">
                                          <label htmlFor="fragranceDescription">
                                            Describe the fragrance
                                          </label>
                                          <textarea
                                            placeholder="NN Characters max"
                                            className="ct_textarea fw-light"
                                            rows="2"
                                            id="fragranceDescription"

                                            name="nose"
                                            defaultValue={""}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.nose}
                                          ></textarea>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group mb-3 mb-xs-3">
                                          <label htmlFor="finishNotesDescription">
                                            Describe the finish notes of the
                                            product
                                          </label>
                                          <textarea
                                            placeholder="NN Characters max"
                                            className="ct_textarea fw-light"
                                            rows="2"
                                            id="finishNotesDescription"
                                            name="finish"
                                            defaultValue={""}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.finish}
                                          ></textarea>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group mb-4 mb-xs-3">
                                          <label htmlFor="palateDescription">
                                            Describe the Palate of the product
                                          </label>
                                          <textarea
                                            placeholder="NN Characters max"
                                            className="ct_textarea fw-light"
                                            rows="2"
                                            id="palateDescription"

                                            name="palate"
                                            defaultValue={""}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.palate}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </>
                            )}

                            {currentTab === 2 && (
                              <div className="ct_dark_grey_form_bg pb-0" id="step_1">
                                <div className="container text-white rounded-lg">
                                  <div className="w-80 w-xs-80 text-white fw-light mb-3 text-md-start">
                                    Please rate the tastes you felt during
                                    testing out this release and rate the volume
                                    of taste from 0 to 5 to specify your
                                    impression.
                                  </div>

                                  <div className="row mb-4 position-relative z-index-1">
                                    <div className="col-md-12">
                                      <div className="mb-3">
                                        <label className="d-block mb-2 text-sm fw-medium">
                                          Flavours presented
                                        </label>
                                        <select onChange={handleSelectChange} className="ct_input form-select" value={formik.values.selectedOptions.map(option => option.value)}>
                                          {options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      {/* <Form.Group>
                                        <Form.Control as="select" onChange={handleSelectChange} value="">
                                          <option value="" disabled>Select options</option>
                                          {options.map((option) => (
                                            <option key={option.value} value={option.value}>
                                              {option.label}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      </Form.Group> */}
                                      <div className="d-flex flex-wrap gap-2">
                                        {formik?.values?.selectedOptions?.length > 0 && formik?.values?.selectedOptions?.map((option, index) => (
                                          <span
                                            key={index}
                                            className="border border-white border-1 px-3 bg-transparent d-flex align-items-center hover_danger"
                                            style={{
                                              borderRadius: "8px",
                                              height: "35px",
                                              marginRight: "5px",
                                              marginBottom: "5px"
                                            }}
                                          >
                                            {option.label}
                                            <span
                                              className="text-white ms-1"
                                              onClick={() => handleRemove(option)}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="#ffffff"
                                                className="bi bi-x"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                                              </svg>
                                            </span>
                                          </span>
                                        ))}

                                        {/* <span
                                          className="border border-white border-1 px-3 bg-transparent d-flex align-items-center hover_danger"
                                          style={{
                                            borderRadius: "8px",
                                            height: "35px",
                                          }}
                                        >
                                          Woody
                                          <span className="text-white ms-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#ffffff"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </span>
                                        </span> */}
                                        {/* <span
                                          className="border border-white border-1 px-3 bg-transparent d-flex align-items-center hover_danger"
                                          style={{
                                            borderRadius: "8px",
                                            height: "35px",
                                          }}
                                        >
                                          Fruity */}
                                        {/* <span className="text-white ms-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#ffffff"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </span>
                                        </span>
                                        <span
                                          className="border border-white border-1 px-3 bg-transparent d-flex align-items-center hover_danger"
                                          style={{
                                            borderRadius: "8px",
                                            height: "35px",
                                          }}
                                        >
                                          Floral
                                          <span className="text-white ms-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#ffffff"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </span>
                                        </span>
                                        <span
                                          className="border border-white border-1 px-3 bg-transparent d-flex align-items-center hover_danger"
                                          style={{
                                            borderRadius: "8px",
                                            height: "35px",
                                          }}
                                        >
                                          Winey
                                          <span className="text-white ms-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#ffffff"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </span>
                                        </span>
                                        <span
                                          className="border border-white border-1 px-3 bg-transparent d-flex align-items-center hover_danger"
                                          style={{
                                            borderRadius: "8px",
                                            height: "35px",
                                          }}
                                        >
                                          Feinty
                                          <span className="text-white ms-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#ffffff"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </span>
                                        </span>
                                        <span
                                          className="border border-white border-1 px-3 bg-transparent d-flex align-items-center hover_danger"
                                          style={{
                                            borderRadius: "8px",
                                            height: "35px",
                                          }}
                                        >
                                          Sulphury
                                          <span className="text-white ms-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#ffffff"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </span>
                                        </span>
                                        <span
                                          className="border border-white border-1 px-3 bg-transparent d-flex align-items-center hover_danger"
                                          style={{
                                            borderRadius: "8px",
                                            height: "35px",
                                          }}
                                        >
                                          Cereal
                                          <span className="text-white ms-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="#ffffff"
                                              className="bi bi-x"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 1 1 .708.708L8.707 8l3.647 3.646a.5.5 0 0 1-.708.708L8 8.707l-3.646 3.647a.5.5 0 0 1-.708-.708L7.293 8 3.646 4.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                          </span>
                                        </span> */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="pb-4">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <RadarChartMain labelsArray={labelsArray} valuesArray={valuesArray} />
                                      </div>
                                      <div className="col-md-6 mt-4 mt-md-0 mt-xs-5">
                                        <h3 className="ct_Cormorant_font fw-regular fs-20 text-xl  mb-3">
                                          Taste diagram built based on your
                                          description
                                        </h3>
                                        <p className="text-white ct_Inter_font fw-regular fs-14 m-0">
                                          The taste you added in the menu above
                                          is displayed on the diagram to
                                          demonstrate how the users will see it.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* {currentTab === 3 && <AromasTab />} */}
                            {currentTab === 4 && (
                              <div className="ct_dark_grey_form_bg pb-0" id="step_1">
                                <div className="w-80 w-xs-80 text-white fw-light mb-3 text-md-start">
                                  Please rate the tastes you felt during testing
                                  out this release and rate the volume of taste
                                  from 0 to 5 to specify your impression.
                                </div>
                                <div className="p-0 container text-white rounded-lg pb-4">
                                  <div className="mb-3">
                                    <label className="d-block mb-2 text-sm fw-normal">
                                      Flavours presented
                                    </label>
                                    <select className="ct_input form-select">
                                      <option value="">
                                        Select the flavours
                                      </option>
                                    </select>
                                  </div>
                                  <div className="border-1 p-4" style={{ borderRadius: "8px", borderColor: 'rgb(255 255 255 / 50%)', borderStyle: 'solid', }}>
                                    <div className="row loopdata_list">
                                      {/* {formik.values.selectedOptions.length > 0 && formik.values.selectedOptions.map((item) => (
                                        // <div className="col-md-6">
                                        //   <div className="">
                                        //     <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                        //       <div className="label_ttl_data ">
                                        //         <label className="fw-normal m-0">
                                        //           {item.label}
                                        //         </label>
                                        //       </div>
                                        //       <div className="Label_count_list">
                                        //         <div className="d-flex gap-1 justify-content-between">
                                        //           <button
                                        //             type="button"
                                        //             className="btn text-white btn-bg-color"
                                        //           >
                                        //             -
                                        //           </button>
                                        //           <button
                                        //             type="button"
                                        //             className="btn text-white  btn-bg-color"
                                        //           >
                                        //             0
                                        //           </button>
                                        //           <button
                                        //             type="button"
                                        //             className="btn text-white btn-bg-color"
                                        //           >
                                        //             1
                                        //           </button>
                                        //           <button
                                        //             type="button"
                                        //             className="btn text-dark btn-bg-color-white"
                                        //           >
                                        //             2
                                        //           </button>
                                        //           <button
                                        //             type="button"
                                        //             className="btn text-white btn-bg-color"
                                        //           >
                                        //             3
                                        //           </button>
                                        //           <button
                                        //             type="button"
                                        //             className="btn text-white btn-bg-color"
                                        //           >
                                        //             4
                                        //           </button>
                                        //           <button
                                        //             type="button"
                                        //             className="btn text-white btn-bg-color"
                                        //           >
                                        //             5
                                        //           </button>
                                        //         </div>
                                        //       </div>
                                        //     </div>
                                        //     <hr />
                                        //   </div>
                                        // </div>
                                        
                                      ))} */}
                                      {formik.values.selectedOptions?.length > 0 && formik.values.selectedOptions?.map((item) => (
                                        <div className="col-12 col-md-6">
                                          <div className="">
                                            <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                              <div className="label_ttl_data ">
                                                <label className="fw-normal m-0">
                                                  {item.label}
                                                </label>
                                              </div>
                                              <div className="Label_count_list">
                                                {/* gap-1 */}
                                                <div className="d-flex  flex-wrap gap-1" >
                                                  {['-', 0, 1, 2, 3, 4, 5].map((value, index) => (
                                                    <button
                                                      key={index}
                                                      type="button"
                                                      onClick={() => handleRatings(item, value)}
                                                      className={`btn ${value === item.rating ? 'text-dark btn-bg-color-white' : 'text-white btn-bg-color'} `}
                                                    >
                                                      {value}
                                                    </button>
                                                  ))}
                                                  {/* <button
                                                    type="button"
                                                    className="btn text-white btn-bg-color"
                                                  >
                                                    -
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn text-white  btn-bg-color"
                                                  >
                                                    0
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn text-white btn-bg-color"
                                                  >
                                                    1
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn text-dark btn-bg-color-white"
                                                  >
                                                    2
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn text-white btn-bg-color"
                                                  >
                                                    3
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn text-white btn-bg-color"
                                                  >
                                                    4
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn text-white btn-bg-color"
                                                  >
                                                    5
                                                  </button> */}
                                                </div>
                                              </div>
                                            </div>
                                            <hr />
                                          </div>
                                        </div>
                                        //   <div className="col-12 col-md-6">
                                        //     <div className="p-2">
                                        //       <div className="d-flex flex-wrap align-items-center justify-content-between gap-2" role="group">
                                        //         <div className="label_ttl_data">
                                        //           <label className="fw-normal m-0">{item.label}</label>
                                        //         </div>
                                        //         <div className="Label_count_list">
                                        //           <div className="d-flex flex-wrap gap-1 ">
                                        //             {['-', 0, 1, 2, 3, 4, 5].map((value, index) => (
                                        //               <button
                                        //                 key={index}
                                        //                 type="button"
                                        //                 className={`btn ${value === 2 ? 'text-dark btn-bg-color-white' : 'text-white btn-bg-color'} `}
                                        //               >
                                        //                 {value}
                                        //               </button>
                                        //             ))}
                                        //           </div>
                                        //         </div>
                                        //       </div>
                                        //       <hr />
                                        //     </div>
                                        //   </div>


                                      ))}
                                      {/* <div className="col-md-6">
                                        <div className="">
                                          <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                            <div className="label_ttl_data ">
                                              <label className="fw-normal m-0">
                                                Woody
                                              </label>
                                            </div>
                                            <div className="Label_count_list">
                                              <div className="d-flex gap-1 justify-content-between">
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  -
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white  btn-bg-color"
                                                >
                                                  0
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  1
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-dark btn-bg-color-white"
                                                >
                                                  2
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  3
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  4
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  5
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="">
                                          <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                            <div className="label_ttl_data ">
                                              <label className="fw-normal m-0">
                                                Feinty
                                              </label>
                                            </div>
                                            <div className="Label_count_list">
                                              <div className="d-flex gap-1 justify-content-between">
                                                <button
                                                  type="button"
                                                  className="btn text-dark btn-bg-color-white"
                                                >
                                                  -
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  0
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  1
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  2
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  3
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  4
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  5
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="">
                                          <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                            <div className="label_ttl_data ">
                                              <label className="fw-normal m-0">
                                                Fruity
                                              </label>
                                            </div>
                                            <div className="Label_count_list">
                                              <div className="d-flex gap-1 justify-content-between">
                                                <button
                                                  type="button"
                                                  className="btn text-dark btn-bg-color-white"
                                                >
                                                  -
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  0
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  1
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  2
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  3
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  4
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  5
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      </div>


                                      <div className="col-md-6">
                                        <div className="">
                                          <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                            <div className="label_ttl_data ">
                                              <label className="fw-normal m-0">
                                                Sulphury
                                              </label>
                                            </div>
                                            <div className="Label_count_list">
                                              <div className="d-flex gap-1 justify-content-between">
                                                <button
                                                  type="button"
                                                  className="btn text-dark btn-bg-color-white"
                                                >
                                                  -
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  0
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  1
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  2
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  3
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  4
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  5
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="">
                                          <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                            <div className="label_ttl_data ">
                                              <label className="fw-normal m-0">
                                                Floral
                                              </label>
                                            </div>
                                            <div className="Label_count_list">
                                              <div className="d-flex gap-1 justify-content-between">
                                                <button
                                                  type="button"
                                                  className="btn text-dark btn-bg-color-white"
                                                >
                                                  -
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  0
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  1
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  2
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  3
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  4
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  5
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="">
                                          <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                            <div className="label_ttl_data ">
                                              <label className="fw-normal m-0">
                                                Cereal
                                              </label>
                                            </div>
                                            <div className="Label_count_list">
                                              <div className="d-flex gap-1 justify-content-between">
                                                <button
                                                  type="button"
                                                  className="btn text-dark btn-bg-color-white"
                                                >
                                                  -
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  0
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  1
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  2
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  3
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  4
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  5
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="">
                                          <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                            <div className="label_ttl_data ">
                                              <label className="fw-normal m-0">
                                                Winey
                                              </label>
                                            </div>
                                            <div className="Label_count_list">
                                              <div className="d-flex gap-1 justify-content-between">
                                                <button
                                                  type="button"
                                                  className="btn text-dark btn-bg-color-white"
                                                >
                                                  -
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  0
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  1
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  2
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  3
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  4
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color btn-bg-color"
                                                >
                                                  5
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      </div>


                                      <div className="col-md-6">
                                        <div className="">
                                          <div className="d-flex align-items-center justify-space-between gap-2" role="group">
                                            <div className="label_ttl_data ">
                                              <label className="fw-normal m-0">
                                                Peaty
                                              </label>
                                            </div>
                                            <div className="Label_count_list">
                                              <div className="d-flex gap-1 justify-content-between">
                                                <button
                                                  type="button"
                                                  className="btn text-dark btn-bg-color-white"
                                                >
                                                  -
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  0
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  1
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  2
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  3
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  4
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn text-white btn-bg-color"
                                                >
                                                  5
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </section>

      <FormSubFooter
        totalPages={3}
        currentPage={currentPage}
        onCancel={cancel}
        onSaveAsDraft={saveAsDraft}
        onProceed={proceed}
        onPublish={proceed}
        onPrevious={onPrevious}
      />
      <Modal centered show={show} onHide={handleClose} dialogClassName="success-modal" >
        <Modal.Body style={{ padding: '0', width: "28.375rem" }}>
          <SuccessModel title={'RELEASE HAS BEEN EDITED'} msg={''} />
        </Modal.Body>
      </Modal>
 </>
      :
      <Loader /> 
    }
    </>
    
  );
};

export default EditRelease;
